import React, { useCallback, useContext, useEffect, useState } from 'react';
import InlinePillComponent from 'components/InlinePill';
import { useTranslation } from 'react-i18next';
import { getApi } from 'utils/api';
import {
  EntitlementsApi,
  CompanyInfoApi,
  SsoSettingsApi,
  CompanySettingsApi,
} from '@procore/ipa-nt-api-client-ts';
import type { CompanyAccountStatusDto } from '@procore/ipa-nt-api-client-ts';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { Flex } from '@procore/core-react';

interface CompanyPillsProps {
  companyId: number;
  env: string;
  companyAccountStatus?: CompanyAccountStatusDto;
}

export const CompanyPills = (props: CompanyPillsProps) => {
  const { env, companyId, companyAccountStatus } = props;
  const { t } = useTranslation(['common']);
  const alert = useContext(IpaAlertContext);
  const [isFreeAccount, setIsFreeAccount] = useState<boolean>(false);
  const [isDemo, setIsDemo] = useState<boolean>(false);
  const [isSsoEnabled, setIsSsoEnabled] = useState<boolean>(false);
  const [isMfaEnabled, setIsMfaEnabled] = useState<boolean>(false);
  const [isErpIntegrated, setIsErpIntegrated] = useState<boolean>(false);

  const initLoad = useCallback(async () => {
    try {
      const companyStatus = await getCompanyStatus();
      const ssoSettings = await getSsoSettings();
      const securitySettings = await getAdvancedSecuritySettings();

      // Check Company Status first
      if (companyStatus) {
        setIsDemo(companyStatus.is_demo);
        setIsErpIntegrated(companyStatus.erp_integrated);
      }
      if (ssoSettings) {
        setIsSsoEnabled(ssoSettings.sso_enabled ?? false);
      }
      if (securitySettings) {
        setIsMfaEnabled(securitySettings.require_mfa_for_members ?? false);
      }

      try {
        const accountFeaturesRes = await getAccountFeatures();
        if (accountFeaturesRes.data) {
          setIsFreeAccount(!accountFeaturesRes.data.has_paid_entitlements);
        }
      } catch (e: any) {
        alert.error(e);
        setIsFreeAccount(false);
      }
    } catch (e: any) {
      alert.error(t('Failed to fetch company settings.'));
      setIsDemo(false);
    }
  }, [companyId, env]);

  useEffect(() => {
    initLoad();
  }, [initLoad]);

  const getAccountFeatures = useCallback(async () => {
    const api = getApi(EntitlementsApi);
    return api.toolEntitlementControllerGetEntitlements(companyId, env);
  }, [companyId, env]);

  const getCompanyStatus = useCallback(async () => {
    if (companyAccountStatus) {
      return companyAccountStatus;
    }
    const api = getApi(CompanyInfoApi);
    const response =
      await api.companyCompanyInfoControllerGetCompanyAccountStatus(
        env,
        companyId,
      );
    return response.data;
  }, [companyId, env, companyAccountStatus]);

  const getSsoSettings = useCallback(async () => {
    const api = getApi(SsoSettingsApi);
    const response =
      await api.companySsoSettingsControllerGetCompanySsoSettings(
        env,
        companyId,
      );
    return response.data;
  }, [companyId, env]);

  const getAdvancedSecuritySettings = useCallback(async () => {
    const api = getApi(CompanySettingsApi);
    const response =
      await api.companySettingsControllerGetCompanyAdvancedSecuritySettings(
        env,
        companyId,
      );
    return response.data;
  }, [companyId, env]);

  return (
    <Flex>
      {isDemo && <InlinePillComponent color="green" text={t('DEMO_COMPANY')} />}
      {isFreeAccount && (
        <InlinePillComponent color="blue" text={t('FREE_ACCOUNT')} />
      )}
      {isSsoEnabled && (
        <InlinePillComponent color="yellow" text={t('SSO_ENABLED')} />
      )}
      {isMfaEnabled && (
        <InlinePillComponent color="red" text={t('MFA_ENABLED')} />
      )}
      {isErpIntegrated && (
        <InlinePillComponent color="red" text={t('ERP_INTEGRATED')} />
      )}
    </Flex>
  );
};

export default CompanyPills;
