import { getUserSetting } from 'utils/utils';

export interface IIpaNav {
  name: string;
  tab_name: string;
  canView?: boolean;
  submenu: IIpaNavSubmenu[];
  display: boolean;
  required_permissions: string[];
}

export interface IIpaNavSubmenu {
  name: string;
  /** Path used when not using proxy */
  path: string;
  /** Path used when using Proxy */
  proxy_path?: string;
  /** Path to original (v1) page */
  v1_path?: string;
  qa: string;
  amplitude: string;
  /** Indicates page is in beta (displays beta component) */
  beta?: boolean;
  /** Permissions required to view the page */
  required_permissions: string[];
}

export const ipaNavMap: IIpaNav[] = [
  {
    name: 'NAV_HOME',
    tab_name: 'jit_user',
    required_permissions: ['jit_escalate', 'su'],
    submenu: [
      {
        name: 'NAV_JIT',
        path: '/v2/jit',
        proxy_path: '/v2/jit',
        v1_path: '/',
        required_permissions: ['jit_escalate'],
        qa: 'jit',
        amplitude: 'ipa.ipa_navigation.jit.clicked',
        beta: true,
      },
      {
        name: 'NAV_SU',
        path: '/v2/super-user',
        proxy_path: '/v2/super-user',
        v1_path: '/su',
        required_permissions: ['su'],
        qa: 'su',
        amplitude: 'ipa.ipa_navigation.su.clicked',
        beta: true,
      },
    ],
    display: true,
  },
  {
    name: 'NAV_COMPANY',
    tab_name: 'company',
    required_permissions: ['company_tab_view', 'super_area_view'],
    display: true,
    submenu: [
      {
        name: 'NAV_COMPANY_INFO',
        path: '/search/company',
        v1_path: '/search/company',
        required_permissions: ['company_tab_view'],
        qa: 'company',
        amplitude: 'ipa.ipa_navigation.revenue_operations.clicked',
        beta: true,
      },
      {
        name: 'NAV_CUSTOM_REPORT_TABS',
        path: '/v2/custom-reports-tabs',
        proxy_path: '/v2/custom-reports-tabs',
        v1_path: '/customer-success/custom-reports-tabs',
        required_permissions: ['company_tab_view'],
        qa: 'custom-report-tabs',
        amplitude: 'ipa.ipa_navigation.custom_reports_tabs.clicked',
      },
      {
        name: 'NAV_WORKFLOW_STATE_TOOL',
        path: '/v2/workflow-state-tool',
        v1_path: '/customer-success/workflow-state-tool',
        proxy_path: '/v2/workflow-state-tool',
        required_permissions: ['company_tab_view', 'cs_view'],
        qa: 'workflow-state-tool',
        amplitude: 'ipa.ipa_navigation.workflow_tab.clicked',
        beta: true,
      },
      {
        name: 'NAV_INSIGHTS',
        path: '/v2/insights',
        proxy_path: '/v2/insights',
        v1_path: '/insights',
        required_permissions: ['company_tab_view'],
        qa: 'insights',
        amplitude: 'ipa.ipa_navigation.insights.clicked',
        beta: true,
      },
      {
        name: 'NAV_ERP_SUPPORT',
        path: '/v2/erp-support',
        proxy_path: '/v2/erp-support',
        required_permissions: ['edit_erp_support_tools'],
        qa: 'erp-support',
        amplitude: 'ipa.ipa_navigation.ipa_support.clicked',
        beta: true,
      },
      {
        name: 'NAV_SUPER_AREA',
        path: '/v2/super',
        proxy_path: '/v2/super',
        required_permissions: ['super_area_view'],
        qa: 'super-area',
        amplitude: 'ipa.ipa_navigation.super_area.clicked',
        beta: true,
      },
    ],
  },
  {
    name: 'NAV_USER_INFORMATION',
    tab_name: 'user_info',
    required_permissions: ['cs_view', 'add_user_to_directory'],
    display: true,
    submenu: [
      {
        name: 'NAV_GLOBAL_USER_SEARCH',
        path: '/search/user',
        required_permissions: ['cs_view'],
        qa: 'customer-success',
        amplitude: 'ipa.ipa_navigation.global_user_search.clicked',
        beta: true,
      },
      {
        name: 'NAV_COMPANY_DIRECTORY',
        path: '/v2/company-directory',
        proxy_path: '/v2/company-directory',
        required_permissions: ['cs_view'],
        qa: 'company-directory',
        amplitude: 'ipa.ipa_navigation.company_directory.clicked',
        beta: true,
      },
      {
        name: 'NAV_EMAIL_MANAGEMENT',
        path: '/v2/email-management',
        proxy_path: '/v2/email-management',
        required_permissions: ['cs_view'],
        qa: 'email-management',
        amplitude: 'ipa.ipa_navigation.email_management.clicked',
        beta: true,
      },
    ],
  },
  {
    name: 'NAV_PAYMENTS',
    tab_name: 'payments',
    required_permissions: [
      'payments_prod_access',
      'payments_operations_int_sbx',
    ],
    display: true,
    submenu: [
      {
        name: 'NAV_PAYMENTS_OPERATIONS',
        path: '/v2/payments',
        v1_path: '/payments',
        proxy_path: '/v2/payments',
        required_permissions: ['payments_prod_access'],
        qa: 'payments_operations',
        amplitude: 'ipa.ipa_navigation.payments.clicked',
        beta: true,
      },
      {
        name: 'NAV_PAYMENTS_OPERATIONS_INT_SBX',
        path: '/v2/payments-integrator-sandbox',
        proxy_path: '/v2/payments-integrator-sandbox',
        required_permissions: ['payments_operations_int_sbx'],
        qa: 'payments_operations_int_sbx',
        amplitude: 'ipa.ipa_navigation.payments_int_sbx.clicked',
      },
    ],
  },
  {
    name: 'NAV_DEV_PORTAL',
    tab_name: 'dev-portal',
    required_permissions: ['dev_portal'],
    display: true,
    submenu: [
      {
        name: 'NAV_INTEGRATOR_SANDBOX_CONFIG',
        path: '/v2/dev-portal/integrator-sandbox-config',
        proxy_path: '/v2/dev-portal/integrator-sandbox-config',
        required_permissions: ['dev_portal'],
        qa: 'integrator-sandbox-config',
        amplitude: 'ipa.ipa_navigation.integrator_sandbox_config.clicked',
      },
      {
        name: 'NAV_MONTHLY_SANDBOX',
        path: '/v2/dev-portal/monthly-sandbox',
        proxy_path: '/v2/dev-portal/monthly-sandbox',
        required_permissions: ['dev_portal'],
        qa: 'monthly-sandbox',
        amplitude: 'ipa.ipa_navigation.monthly_sandbox.clicked',
      },
      {
        name: 'NAV_OAUTH_APPLICATIONS',
        path: '/v2/dev-portal/oauth-applications',
        proxy_path: '/v2/dev-portal/oauth-applications',
        required_permissions: ['dev_portal'],
        qa: 'oauth-applications',
        amplitude: 'ipa.ipa_navigation.oauth_applications.clicked',
      },
      {
        name: 'NAV_OAUTH_SECRET_ROTATIONS',
        path: '/v2/dev-portal/oauth-secret-rotations',
        proxy_path: '/v2/dev-portal/oauth-secret-rotations',
        required_permissions: ['dev_portal'],
        qa: 'oauth-secret-rotations',
        amplitude: 'ipa.ipa_navigation.oauth_secret_rotations.clicked',
      },
      {
        name: 'NAV_OAUTH_CACHE',
        path: '/v2/dev-portal/oauth-cache',
        proxy_path: '/v2/dev-portal/oauth-cache',
        required_permissions: ['dev_portal'],
        qa: 'oauth-cache',
        amplitude: 'ipa.ipa_navigation.oauth-cache.clicked',
      },
    ],
  },
  {
    name: 'NAV_ADMIN',
    tab_name: 'admin',
    required_permissions: ['view_admin'],
    display: true,
    submenu: [
      {
        name: 'NAV_DASHBOARD',
        path: '/v2/dashboard',
        proxy_path: '/v2/dashboard',
        required_permissions: ['view_admin'],
        qa: 'dashboard',
        amplitude: 'ipa.ipa_navigation.dashboard.clicked',
        beta: true,
      },
      {
        name: 'NAV_JIT_ADMIN',
        path: '/v2/jit-admin',
        proxy_path: '/v2/jit-admin',
        required_permissions: ['view_admin'],
        qa: 'jit-admin',
        amplitude: 'ipa.ipa_navigation.jit_admin.clicked',
        beta: true,
      },
      {
        name: 'NAV_JIT_SYNC',
        path: '/v2/jit-sync',
        proxy_path: '/v2/jit-sync',
        required_permissions: ['view_admin'],
        qa: 'jit-sync',
        amplitude: 'ipa.ipa_navigation.jit_sync.clicked',
        beta: true,
      },
      {
        name: 'NAV_JIT_AUDIT_LOG',
        path: '/v2/jit-audit-log',
        proxy_path: '/v2/jit-audit-log',
        required_permissions: ['view_admin'],
        qa: 'jit-audit-log',
        amplitude: 'ipa.ipa_navigation.jit_audit_log.clicked',
        beta: true,
      },
      {
        name: 'NAV_OKTA_USERS',
        path: '/v2/users',
        proxy_path: '/v2/users',
        required_permissions: ['view_admin'],
        qa: 'users',
        amplitude: 'ipa.ipa_navigation.okta_users.clicked',
        beta: true,
      },
      {
        name: 'NAV_OKTA_GROUPS',
        path: '/v2/okta-groups',
        proxy_path: '/v2/okta-groups',
        required_permissions: ['view_admin'],
        qa: 'okta-groups',
        amplitude: 'ipa.ipa_navigation.okta_groups.clicked',
        beta: true,
      },
      {
        name: 'NAV_HISTORY',
        path: '/v2/events',
        proxy_path: '/v2/events',
        required_permissions: ['view_admin'],
        qa: 'events',
        amplitude: 'ipa.ipa_navigation.history.clicked',
        beta: true,
      },
      {
        name: 'NAV_SU_PROMOTION_REPORT',
        path: '/v2/su-promotion-report',
        proxy_path: '/v2/su-promotion-report',
        required_permissions: ['view_admin'],
        qa: 'su-promotion-report',
        amplitude: 'ipa.ipa_navigation.su_promotion_report.clicked',
        beta: true,
      },
      {
        name: 'NAV_AUDIT_LOGS',
        path: '/v2/audit_logs',
        required_permissions: ['view_admin'],
        qa: 'audit-logs',
        amplitude: 'ipa.ipa_navigation.audit_logs.clicked',
        beta: true,
      },
      {
        name: 'NAV_SU_SYNC_REPORT',
        path: '/v2/su-sync',
        proxy_path: '/v2/su-sync',
        required_permissions: ['view_admin'],
        qa: 'sync-report',
        amplitude: 'ipa.ipa_navigation.sync_report.clicked',
      },
      {
        name: 'NAV_PERMISSIONS',
        proxy_path: '/v2/permissions',
        path: '/v2/permissions',
        required_permissions: ['view_admin'],
        qa: 'permissions',
        amplitude: 'ipa.ipa_navigation.permissions.clicked',
        beta: true,
      },
      {
        name: 'NAV_GLOBAL_ANNOUNCEMENTS',
        path: '/v2/global-announcement-admin',
        proxy_path: '/v2/global-announcement-admin',
        required_permissions: ['view_admin'],
        qa: 'global-announcements-admin',
        amplitude: 'ipa.ipa_navigation.global_announcements_admin.clicked',
      },
      {
        name: 'NAV_SYSTEM_SETTINGS',
        path: '/v2/system-settings',
        proxy_path: '/v2/system-settings',
        required_permissions: ['view_admin'],
        qa: 'system-settings',
        amplitude: 'ipa.ipa_navigation.system_settings_admin.clicked',
        beta: true,
      },
      {
        name: 'NAV_ECRION_PDF_ADMINS',
        path: '/v2/pdf-ecrion-admins',
        proxy_path: '/v2/pdf-ecrion-admins',
        required_permissions: ['ecrion_pdf_admins'],
        qa: 'pdf-ecrion-admins',
        amplitude: 'ipa.ipa_navigation.ecrion_pdf_admins.clicked',
        beta: true,
      },
    ],
  },
  {
    name: 'NAV_USER',
    tab_name: 'user',
    required_permissions: [],
    display: false,
    submenu: [
      {
        name: 'USER_SETTINGS',
        path: '/v2/settings',
        proxy_path: '/v2/settings',
        required_permissions: [],
        qa: 'user-settings',
        amplitude: '',
        beta: true,
      },
      {
        name: 'USER_MY_ACTIVITY',
        path: '/v2/my-activity',
        proxy_path: '/v2/my-activity',
        required_permissions: [],
        qa: 'user-my-activity',
        amplitude: '',
        beta: true,
      },
    ],
  },
];

export const ipaNavMapByKey = Object.fromEntries(
  ipaNavMap.flatMap((section) =>
    section.submenu.map((item) => [item.name, item]),
  ),
);

/**
 * Get path for nav item by name, considering if elixir path should be used
 *
 * @throws Error if invalid nav item name
 * @param name
 * @returns
 */
export const getNavPath = (name: string): string => {
  const navItem = ipaNavMapByKey[name];
  if (!navItem) {
    throw new Error(`Invalid nav item: ${name}`);
  }

  if ('config' in window) {
    const config = (window as any).config as any;
    if (config.using_proxy) {
      return navItem.proxy_path ? navItem.proxy_path : navItem.path;
    }
  }

  return navItem.path;
};

(window as any).ipaNavMapByKey = ipaNavMapByKey;
