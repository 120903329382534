import React, {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import {
  Search,
  ListSearch,
  FileLock,
  WrenchPencil,
} from '@procore/core-icons';
import { AppContext } from 'contexts/AppContext';
import type { IWidgetSelection } from './interfaces';
import GlobalDirectorySearch from 'components/Homepage/GlobalDirectorySearch';
import CompanySearch from 'components/Homepage/CompanySearch';
import CompanyDirectorySearch from 'components/Homepage/CompanyDirectorySearch';
import JIT from 'components/Homepage/JIT';
import WorkflowStateTool from 'components/Homepage/WorkflowStateTool';

export interface HomepageContextProps {
  widgets: IWidgetSelection[];
  usedWidgets: string[];
  updateUsedWidgets(name: string | string[], action?: string): void;
  resetUsedWidgets(): void;
  getWidgetByName(name: string): IWidgetSelection;
}

interface ContextProps {
  children: JSX.Element;
}

interface ContextConsumerProps {
  children: any;
}

export const HomepageContext = createContext({} as HomepageContextProps);

export const HomepageContextProvider = (props: ContextProps) => {
  const [usedWidgets, setUsedWidgets] = useState<string[]>([]);
  const { userSettings } = useContext(AppContext);
  const [widgets] = useState<IWidgetSelection[]>([
    {
      id: 'gds',
      name: 'gds',
      icon: ListSearch,
      description: 'GDS',
      isUsed: false,
      permissions: ['cs_view'],
      component: GlobalDirectorySearch,
    },
    {
      id: 'company-search',
      name: 'company-search',
      icon: Search,
      description: 'COMPANY_SEARCH',
      isUsed: false,
      permissions: ['company_tab_view'],
      component: CompanySearch,
    },
    {
      id: 'company-directory-search',
      name: 'company-directory-search',
      icon: Search,
      description: 'CDS',
      isUsed: false,
      permissions: ['cs_view'],
      component: CompanyDirectorySearch,
    },
    {
      id: 'jit',
      name: 'jit',
      icon: FileLock,
      description: 'JIT',
      isUsed: false,
      permissions: ['jit_escalate'],
      component: JIT,
    },
    {
      id: 'workflow-state-tool',
      name: 'workflow-state-tool',
      icon: WrenchPencil,
      description: 'WST',
      isUsed: false,
      permissions: ['company_tab_view', 'cs_view'],
      component: WorkflowStateTool,
    },
  ]);

  const updateUsedWidgets = useCallback(
    (name: string | string[], action?: 'add' | 'delete') => {
      if (Array.isArray(name)) {
        setUsedWidgets(name);
      } else if (action === 'add') {
        setUsedWidgets((prev) => [...prev, name]);
      } else if (action === 'delete') {
        setUsedWidgets(usedWidgets.filter((widget) => widget !== name));
      }
    },
    [usedWidgets],
  );

  const resetUsedWidgets = useCallback(() => {
    setUsedWidgets(userSettings.homepage_layout || []);
  }, [userSettings.homepage_layout]);

  const getWidgetByName = useCallback(
    (name: string) => {
      return widgets.find((w) => w.name === name);
    },
    [widgets],
  );

  return (
    <HomepageContext.Provider
      value={useMemo(
        () => ({
          widgets,
          usedWidgets,
          updateUsedWidgets,
          resetUsedWidgets,
          getWidgetByName,
        }),
        [
          widgets,
          usedWidgets,
          getWidgetByName,
          resetUsedWidgets,
          updateUsedWidgets,
        ],
      )}
    >
      {props.children}
    </HomepageContext.Provider>
  );
};

export const HomepageContextConsumer = (props: ContextConsumerProps) => {
  return <HomepageContext.Consumer>{props.children}</HomepageContext.Consumer>;
};

export default HomepageContextProvider;
