import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  createContext,
} from 'react';

import {
  Page,
  Card,
  Flex,
  Spinner,
  Box,
  EmptyState,
  Title,
  Dropdown,
  Breadcrumbs,
  Link,
  H1,
  spacing,
} from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { getApi } from 'utils/api';
import { CompanyInfoApi } from '@procore/ipa-nt-api-client-ts';
import type { CompanyAccountStatusDto } from '@procore/ipa-nt-api-client-ts';
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import CompanyPills from 'components/CompanyPills';
import EnvironmentSwitcher from 'components/EnvironmentSwitcher';
import ProjectListPage from './ProjectList';
import type { ProjectManagementContextProps } from './interfaces';
import { generateUrl } from './urlGenerator';

import ExpenseAllocationsPage from './ExpenseAllocations';
import SubmittalsPage from './Submittals';
import JitWidget from 'components/JitWidget';
import { AppContext } from 'contexts/AppContext';
import CompanyLink from 'components/CompanyLink';
import { can } from 'utils/permissions.helper';
import ContextInfoPopup from 'components/ContextInfoPopup';

export const ProjectManagementContext = createContext(
  {} as ProjectManagementContextProps,
);

export const ProjectManagementPage = () => {
  const { t } = useTranslation(['project-management']);
  const { t: tCommon } = useTranslation(['common']);
  const { company_id: id, page: _page } = useParams();
  const page = _page ?? '';

  const navigate = useNavigate();
  const alert = useContext(IpaAlertContext);
  const context = useContext(ProjectManagementContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [companyStatus, setCompanyStatus] = useState<CompanyAccountStatusDto>();
  const { userSettings, userConfig } = useContext(AppContext);

  context.env = searchParams.get('env') ?? 'PROD';
  context.contactId = Number(searchParams.get('contactId')) || undefined;
  context.loginId = Number(searchParams.get('loginId')) || undefined;
  context.companyId = Number(id);
  context.dataSources = {};

  const pages: { [key: string]: any } = {
    '': {
      label: t('PROJECT_MANAGEMENT'),
      component: ProjectListPage,
    },
    'expense-allocations': {
      label: t('EXPENSE_ALLOCATIONS'),
      component: ExpenseAllocationsPage,
    },
    submittals: {
      label: t('COMPANY_SUBMITTALS'),
      component: SubmittalsPage,
    },
  };

  const loadCompanyStatus = useCallback(async () => {
    alert.closeAlert();
    setCompanyStatus(undefined);
    setIsInit(false);
    try {
      setLoading(true);

      const api = getApi(CompanyInfoApi);
      const res = await api.companyCompanyInfoControllerGetCompanyAccountStatus(
        context.env,
        context.companyId,
      );
      if (res.data) {
        setCompanyStatus(res.data);
        context.companyName = res.data.name;
        context.isActiveCompany = res.data.is_active;
        context.isDemoCompany = res.data.is_demo;
        context.company = res.data;
      }
      setIsInit(true);
    } catch (e: any) {
      alert.error(e);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    loadCompanyStatus();
  }, [id]);

  const changeEnvironment = (newEnv: string) => {
    navigate(
      generateUrl(page, {
        ...context,
        env: newEnv,
      }),
    );
  };

  if (!pages[page]) {
    return <Navigate replace to={'/404'} />;
  }

  const currentPage = pages[page];

  return (
    <ProjectManagementContext.Provider value={context}>
      <Page>
        <Page.Main style={{ flex: 'auto' }}>
          <Page.Header>
            <Page.Breadcrumbs>
              <Breadcrumbs>
                <Link href={`/v2/company/${id}`}>
                  <Breadcrumbs.Crumb>{tCommon('COMPANY')}</Breadcrumbs.Crumb>
                </Link>
                {context.contactId && context.loginId && (
                  <Link
                    href={`/v2/company/${id}/contact/${context.contactId}/login/${context.loginId}/contact-info`}
                  >
                    <Breadcrumbs.Crumb>
                      {tCommon('CONTACT_INFORMATION')}
                    </Breadcrumbs.Crumb>
                  </Link>
                )}
                {page === '' ? (
                  <Breadcrumbs.Crumb active>
                    {t('PROJECT_MANAGEMENT')}
                  </Breadcrumbs.Crumb>
                ) : (
                  <Link
                    href={`/v2/company/${id}/project-management?env=${context.env}`}
                  >
                    <Breadcrumbs.Crumb>
                      {t('PROJECT_MANAGEMENT')}
                    </Breadcrumbs.Crumb>
                  </Link>
                )}
                {page !== '' && (
                  <Breadcrumbs.Crumb active>
                    {t(pages[page].label)}
                  </Breadcrumbs.Crumb>
                )}
              </Breadcrumbs>
            </Page.Breadcrumbs>
            <Title>
              <Title.Text>
                <H1 data-qa="project-management-title">
                  {t(pages[page].label)}
                </H1>{' '}
              </Title.Text>
              <Title.Actions style={{ paddingBottom: spacing.xs }}>
                <Dropdown>
                  {Object.keys(pages).map((key) => (
                    <Dropdown.Item item={key} key={key} selected={key === page}>
                      <a href={generateUrl(key, context)}>{pages[key].label}</a>
                    </Dropdown.Item>
                  ))}
                </Dropdown>
              </Title.Actions>
            </Title>
          </Page.Header>
          <Page.Body>
            <Page.Row>
              <Page.Column>
                <Box paddingBottom="lg">
                  {companyStatus && (
                    <Flex gap="sm">
                      <Box data-qa="company-name-div">
                        <CompanyLink
                          companyId={companyStatus.id}
                          companyName={companyStatus.name}
                          env={context.env}
                        />
                        <CompanyPills
                          companyId={context.companyId}
                          env={context.env}
                        />
                      </Box>
                      <Box>
                        <ContextInfoPopup />
                      </Box>
                      <Box marginLeft="auto">
                        <EnvironmentSwitcher
                          initialValue={context.env}
                          onChange={changeEnvironment}
                        />
                      </Box>

                      {can({ user: userConfig }, 'jit_escalate') && (
                        <Box>
                          <JitWidget
                            env={context.env}
                            companyId={context.companyId}
                            companyName={companyStatus.name}
                            isPinRequired={
                              companyStatus.is_pin_required_for_jit
                            }
                          />
                        </Box>
                      )}
                    </Flex>
                  )}
                </Box>

                {isLoading && (
                  <Flex justifyContent="center">
                    <Spinner loading={isLoading} />
                  </Flex>
                )}
                {isInit && !isLoading && !companyStatus && (
                  <Card>
                    <Box padding="lg" marginBottom="lg">
                      <EmptyState>
                        <EmptyState.NoItems />
                        <EmptyState.Title>{t('EMPTY_STATE')}</EmptyState.Title>
                      </EmptyState>
                    </Box>
                  </Card>
                )}

                {isInit &&
                  !isLoading &&
                  companyStatus &&
                  React.createElement(pages[page].component)}
              </Page.Column>
            </Page.Row>
          </Page.Body>
        </Page.Main>
      </Page>
    </ProjectManagementContext.Provider>
  );
};

export default ProjectManagementPage;
