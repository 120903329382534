import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Title, H2, Button, Form, spacing } from '@procore/core-react';
import * as yup from 'yup';
import sanitizeHtml from 'sanitize-html';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'contexts/AppContext';
import { FormWrapper, FormButtonWrapper } from 'styles';
import type { ISearchForm } from 'pages/CompanyDirectory/company.directory.interface';

const CompanyDirectorySearch = () => {
  const navigate = useNavigate();
  const { env } = useContext(AppContext);
  const { t: tCommon } = useTranslation(['common']);
  const { t } = useTranslation('homepage', { keyPrefix: 'MODULES' });

  const doSearch = (values: ISearchForm) => {
    const searchStr = sanitizeHtml(values.search, {
      allowedTags: [],
      allowedAttributes: {},
    });
    navigate(`/v2/company-directory/${searchStr}?env=${env}&homepage=true`);
  };

  const isSearchDisabled = (dirty: boolean, values: ISearchForm) => {
    return !dirty || values.search === '';
  };

  return (
    <Box data-qa="homepage-company-directory-search">
      <Title>
        <Title.Text>
          <H2>{t('CDS')}</H2>
        </Title.Text>
      </Title>
      <FormWrapper marginTop={`${spacing.md}px`}>
        <Form
          view="create"
          enableReinitialize={true}
          initialValues={{
            search: '',
          }}
          validationSchema={yup.object().shape({
            search: yup.number().required(tCommon('REQUIRED')),
          })}
          onSubmit={(values) => doSearch(values)}
        >
          {(props: any) => {
            const { dirty, values, handleSubmit } = props;
            return (
              <Form.Form
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
              >
                <Form.Row>
                  <Form.Text
                    autoFocus
                    name="search"
                    type="number"
                    data-qa="company-directory-search-field"
                    label={tCommon('COMPANY_ID')}
                    colWidth={10}
                  />
                  <FormButtonWrapper>
                    <Button
                      type="submit"
                      variant="primary"
                      data-qa="company-directory-search-btn"
                      disabled={isSearchDisabled(dirty, values)}
                    >
                      {tCommon('GET_INFO')}
                    </Button>
                  </FormButtonWrapper>
                </Form.Row>
              </Form.Form>
            );
          }}
        </Form>
      </FormWrapper>
    </Box>
  );
};

export default CompanyDirectorySearch;
