import React, { useCallback, useContext, useState } from 'react';
import { Page, Card, Box, Title, H1, H2 } from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import JitAuditLog from 'components/JitAuditLog';
import { JitEnvironment } from './JitEnvironment';
import { Link, useSearchParams } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import { can } from 'utils/permissions.helper';
import { AppContext } from 'contexts/AppContext';

export const JitHomePage = () => {
  const { t } = useTranslation(['jit']);
  const alert = useContext(IpaAlertContext);
  const { userConfig } = useContext(AppContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [lastRefresh, setLastRefresh] = useState<Date>();

  const env = sanitizeHtml(searchParams.get('env'));
  const companyId = sanitizeHtml(searchParams.get('companyId'));
  const accessLevel = sanitizeHtml(searchParams.get('accessLevel'));
  const reasonCategory = sanitizeHtml(searchParams.get('reason'));
  const length = sanitizeHtml(searchParams.get('duration'));
  const pdmSupport = sanitizeHtml(searchParams.get('pdmSupport'));
  let redirectUrl = sanitizeHtml(searchParams.get('redirectUrl'));

  const successHandler = (msg: string) => {
    alert.success(msg);
  };
  const errorHandler = (err: string) => {
    alert.error(err);
  };

  const hasSuAccess = can({ user: userConfig }, ['su']);

  const allowedZones =
    userConfig.user?.allowedZones?.filter(
      (zone) => zone !== 'INTEGRATOR_SANDBOX',
    ) ?? [];

  return (
    <Page>
      <Page.Main style={{ flex: 'auto' }}>
        <Page.Header>
          <Title>
            <Title.Text data-qa={'jit-home-title'}>
              <H1>{t('JIT_HOME_TITLE')}</H1>{' '}
            </Title.Text>
            <Title.Actions>
              {hasSuAccess && <Link to="/v2/super-user">{t('LINK_SU')}</Link>}
            </Title.Actions>
          </Title>
        </Page.Header>
        <Page.Body>
          <Card key={`jit-${lastRefresh}`}>
            <Box padding="lg">
              <H2>{t('JIT_HOME_SUBHEADING')}</H2>
              {allowedZones.map((e) => (
                <JitEnvironment
                  key={e}
                  env={e}
                  companyId={e === env ? companyId : undefined}
                  accessLevel={e === env ? accessLevel : undefined}
                  reason={e === env ? reasonCategory : undefined}
                  redirectUrl={e === env ? redirectUrl : undefined}
                  escalation_length={e === env ? length : undefined}
                  pdmSupport={e === env ? pdmSupport : undefined}
                  setSuccessMessage={successHandler}
                  setErrorMessage={errorHandler}
                />
              ))}
            </Box>
          </Card>
          <Box paddingTop="lg" paddingBottom="lg">
            <Card>
              <Box padding="lg">
                <Box marginBottom="lg">
                  <H2>{t('JIT_AUDIT_LOG_TITLE', { ns: 'jit-audit-log' })}</H2>
                </Box>
                <JitAuditLog
                  dateRange={undefined}
                  jitSessions={undefined}
                  pageSize={undefined}
                  total={undefined}
                  refresh={() => {
                    setLastRefresh(new Date());
                  }}
                />
              </Box>
            </Card>
          </Box>
        </Page.Body>
      </Page.Main>
    </Page>
  );
};

export default JitHomePage;
