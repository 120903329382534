import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Form,
  H2,
  Link,
  Switch,
  Typography,
  Grid,
} from '@procore/core-react';
import { ProjectContext } from 'contexts/ProjectContext';
import { ProjectScheduleContext } from '.';
import { ExternalLink } from '@procore/core-icons';
import {
  DAYS_OF_WEEK,
  TIMES_OF_DAY,
  PROJECT_SCHEDULE_EMAIL_COVERAGE,
} from 'common/constants';
import type { ProjectScheduleSettingsDtoEmailSettings } from '@procore/ipa-nt-api-client-ts';
import { getDateTime } from 'utils/datetime';
import type { ISelectOption } from 'interfaces/common.interface';
import { StyledFormSelect } from 'styles';

export interface ProjectScheduleSettingsProps {
  id: string;
  save: any;
}

interface IEmailSettings
  extends Omit<
    ProjectScheduleSettingsDtoEmailSettings,
    'day_of_week' | 'hour_to_send' | 'weeks_to_show'
  > {
  day_of_week: ISelectOption;
  hour_to_send: ISelectOption;
  weeks_to_show: ISelectOption;
  frequency: ISelectOption;
}

const ProjectScheduleEmailCard = (props: ProjectScheduleSettingsProps) => {
  const context = useContext(ProjectContext);
  const { t: tCommon } = useTranslation(['common']);
  const pageConfig = context.pageConfig;
  const config = pageConfig.card('ProjectScheduleEmail');
  const [selectedSendWeekly, setSelectedSendWeekly] = useState<any>(false);
  const [lastSentAt, setLastSentAt] = useState<Date>(null);
  const [nextScheduledAt, setNextScheduledAt] = useState<Date>(null);
  const [timeZone, setTimeZone] = useState<string>('');
  const [initialValues, setInitialValues] = useState<IEmailSettings>(
    {} as IEmailSettings,
  );

  const FREQUENCY = [
    {
      id: 1,
      label: config.fields.weekly.label,
    },
  ];

  const {
    dataSources: { scheduleSettings },
  } = useContext(ProjectScheduleContext);

  useEffect(() => {
    const temp: any = {};
    for (const key in scheduleSettings?.data?.email_settings) {
      if (key === 'day_of_week') {
        temp[key] = DAYS_OF_WEEK[scheduleSettings?.data?.email_settings[key]];
      } else if (key === 'hour_to_send') {
        temp[key] = TIMES_OF_DAY[scheduleSettings?.data?.email_settings[key]];
      } else if (key === 'weeks_to_show') {
        temp[key] =
          PROJECT_SCHEDULE_EMAIL_COVERAGE[
            scheduleSettings?.data?.email_settings[key] - 1
          ];
      } else {
        temp[key] =
          scheduleSettings?.data?.email_settings[
            key as keyof ProjectScheduleSettingsDtoEmailSettings
          ];
      }
    }
    temp.frequency = { id: 1, label: config.fields.weekly.label };
    setInitialValues(temp);

    if (temp.send_weekly) {
      setSelectedSendWeekly(temp.send_weekly);
    }
    if (temp.last_sent_at) {
      setLastSentAt(new Date(temp.last_sent_at));
    }
    if (temp.next_scheduled_at) {
      setNextScheduledAt(new Date(temp.next_scheduled_at));
    }
  }, [scheduleSettings]);

  return (
    <div key={props.id}>
      <hr />
      <Form
        enableReinitialize={true}
        view="update"
        initialValues={initialValues}
      >
        {() => {
          return (
            <Form.Form data-qa="project-schedule-email-form">
              <Box>
                <Box marginTop="lg">
                  <H2>{config.label}</H2>
                  {config.fields.sub_label.label}
                  <span>&nbsp;</span>
                  <Link href="https://support.procore.com/products/online/user-guide/project-level/schedule/tutorials/send-weekly-project-resource-and-schedule-lookaheads-emails">
                    {tCommon('PROCORE_SUPPORT')}
                    <ExternalLink
                      size="sm"
                      style={{ verticalAlign: 'text-bottom' }}
                    />
                  </Link>
                </Box>
                <Box marginTop="xl" marginBottom="xl">
                  <Grid>
                    <Grid.Row>
                      <Switch
                        data-qa="project-schedule-email-send-weekly-switch"
                        checked={selectedSendWeekly}
                        onChange={() => {
                          setSelectedSendWeekly(!selectedSendWeekly);
                          props.save({ send_weekly: !selectedSendWeekly });
                        }}
                      />
                      &nbsp;
                      <Typography>
                        {selectedSendWeekly
                          ? tCommon('ENABLED')
                          : tCommon('DISABLED')}
                      </Typography>
                    </Grid.Row>
                  </Grid>
                </Box>
                {selectedSendWeekly && (
                  <Box>
                    <Form.Row>
                      <StyledFormSelect
                        name="frequency"
                        label={config.fields.frequency.label}
                        disabled={true}
                        data-qa="project-schedule-email-frequency-select"
                        options={FREQUENCY}
                        colStart={1}
                        colWidth={3}
                      />
                      <StyledFormSelect
                        label={tCommon('DAY')}
                        name="day_of_week"
                        data-qa="project-schedule-email-day-of-week-select"
                        onSelect={(v) => {
                          props.save({ day_of_week: v.item.id });
                        }}
                        options={DAYS_OF_WEEK.map((day) => {
                          return {
                            ...day,
                            label: tCommon(`DAYS_OF_WEEK.${day.label}`),
                          };
                        })}
                        onSearch={false}
                        onClear={false}
                        colStart={4}
                        colWidth={3}
                      />
                      <StyledFormSelect
                        label={config.fields.time.label}
                        tooltip={config.fields.time.tooltip}
                        name="hour_to_send"
                        data-qa="project-schedule-email-hour-to-send-select"
                        options={TIMES_OF_DAY}
                        onSelect={(v) => {
                          props.save({ hour_to_send: v.item.id });
                        }}
                        onSearch={false}
                        onClear={false}
                        colStart={7}
                        colWidth={3}
                      />
                      <StyledFormSelect
                        label={config.fields.coverage.label}
                        tooltip={config.fields.coverage.tooltip}
                        name="weeks_to_show"
                        data-qa="project-schedule-email-weeks-to-show-select"
                        onSelect={(v) => {
                          props.save({ weeks_to_show: v.item.id });
                        }}
                        options={PROJECT_SCHEDULE_EMAIL_COVERAGE.map(
                          (coverage) => {
                            return {
                              ...coverage,
                              label: tCommon(`WEEK_PERIODS.${coverage.label}`),
                            };
                          },
                        )}
                        onSearch={false}
                        onClear={false}
                        colStart={10}
                        colWidth={3}
                      />
                    </Form.Row>
                    <Typography>
                      {config.fields.last_sent.label}:&nbsp;
                      {getDateTime(lastSentAt, context.timeZone)} | &nbsp;
                      {config.fields.next_scheduled.label}:&nbsp;
                      {getDateTime(nextScheduledAt, context.timeZone)}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Form.Form>
          );
        }}
      </Form>
    </div>
  );
};

export default ProjectScheduleEmailCard;
