import React, { useState, useCallback, useContext, useEffect } from 'react';
import {
  Page,
  Card,
  Button,
  Box,
  Title,
  Panel,
  Flex,
  EmptyState,
} from '@procore/core-react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'contexts/AppContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { HomepageContext } from './HomepageContext';
import { AsideWrapper, EmptyStateDescription } from './styles';
import WidgetSelection from './WidgetSelection';
import DropTarget from './DropTarget';
import type { IWidgetSelection, DropTargetState } from './interfaces';
import { UserSettingsApi } from '@procore/ipa-nt-api-client-ts';
import type { UserSettingUpdateDto } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';

const HomeV2 = () => {
  const { t } = useTranslation('homepage');
  const { userSettings, updateUserSettings } = useContext(AppContext);
  const [isInit, setIsInit] = useState<boolean>(false);
  const alert = useContext(IpaAlertContext);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [dropTargets, setDropTargets] = useState<DropTargetState[]>([
    { lastDroppedItem: null },
  ]);
  const {
    widgets,
    usedWidgets,
    updateUsedWidgets,
    resetUsedWidgets,
    getWidgetByName,
  } = useContext(HomepageContext);

  const handleDrop = useCallback(
    (index: number, item: IWidgetSelection) => {
      dropTargets[index].lastDroppedItem = item;
      if (widgets.length > dropTargets.length) {
        setDropTargets([...dropTargets, { lastDroppedItem: null }]);
      }
    },
    [dropTargets, widgets],
  );

  const EditButton = () => {
    return (
      <Button
        variant="secondary"
        onClick={() => {
          setIsEditMode(!isEditMode);
          resetUsedWidgets();
        }}
      >
        {isEditMode
          ? t('CANCEL', { ns: 'common' })
          : t('CUSTOMIZE_HOMEPAGE_LAYOUT')}
      </Button>
    );
  };

  const saveHomePageLayout = async () => {
    alert.closeAlert();
    try {
      const updatedSettings: UserSettingUpdateDto = { ...userSettings };
      updatedSettings.homepage_layout = usedWidgets;
      const api = getApi(UserSettingsApi);
      const res = await api.settingsControllerUpdate(updatedSettings);

      updateUserSettings(res.data);
      setIsEditMode(!isEditMode);
      alert.success(t('HOMEPAGE_LAYOUT_UPDATED'));
    } catch (error: any) {
      alert.error(error);
    }
  };

  useEffect(() => {
    if (
      userSettings.homepage_layout.length > 0 &&
      !usedWidgets.length &&
      !isInit
    ) {
      updateUsedWidgets(userSettings.homepage_layout);
      setIsInit(true);
    }
  }, [
    userSettings.homepage_layout,
    updateUsedWidgets,
    usedWidgets.length,
    isInit,
  ]);

  useEffect(() => {
    const initDropTargets = () => {
      if (!usedWidgets.length) {
        setDropTargets([{ lastDroppedItem: null }]);
      } else {
        const temp: DropTargetState[] = [];
        usedWidgets.forEach((name) => {
          const widget = getWidgetByName(name);
          if (widget) {
            temp.push({ lastDroppedItem: widget });
          }
        });
        if (usedWidgets.length < widgets.length) {
          temp.push({ lastDroppedItem: null });
        }
        setDropTargets(temp);
      }
    };

    initDropTargets();
  }, [usedWidgets, getWidgetByName, widgets.length]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Page>
        <Page.Main>
          <Page.Body>
            <Card>
              <Box padding="lg">
                <Title>
                  <Title.Text></Title.Text>
                  <Title.Actions>
                    <EditButton />
                    {isEditMode && (
                      <Button
                        variant="primary"
                        disabled={
                          userSettings.homepage_layout.toString() ===
                          usedWidgets.toString()
                        }
                        onClick={saveHomePageLayout}
                      >
                        {t('SAVE_LAYOUT')}
                      </Button>
                    )}
                  </Title.Actions>
                </Title>
                <Box marginTop="xl">
                  <Flex wrap="wrap" gap="lg">
                    {dropTargets.map((target, index) => (
                      <DropTarget
                        isEditMode={isEditMode}
                        onDrop={(item) => {
                          handleDrop(index, item);
                          updateUsedWidgets(item.id, 'add');
                        }}
                        key={index}
                        lastDroppedItem={target.lastDroppedItem}
                      />
                    ))}
                  </Flex>
                </Box>
                {!usedWidgets.length && !isEditMode && (
                  <EmptyState>
                    <EmptyState.NoItems />
                    <EmptyState.Title>{t('HOMEPAGE_EMPTY')}</EmptyState.Title>
                    <EmptyState.Description>
                      <EmptyStateDescription>
                        <ul>
                          <li>{t('CUSTOMIZE_STEP1')}</li>
                          <li>{t('CUSTOMIZE_STEP2')}</li>
                          <li>{t('CUSTOMIZE_STEP3')}</li>
                        </ul>
                      </EmptyStateDescription>
                    </EmptyState.Description>
                  </EmptyState>
                )}
              </Box>
            </Card>
          </Page.Body>
        </Page.Main>
        <AsideWrapper
          className={isEditMode ? '' : 'closed'}
          data-qa="aside-wrapper"
        >
          <Page.Aside open={true}>
            <Panel>
              <Panel.Body>
                <WidgetSelection />
              </Panel.Body>
            </Panel>
          </Page.Aside>
        </AsideWrapper>
      </Page>
    </DndProvider>
  );
};

export default HomeV2;
