import type { PageConfig } from 'pages/page-config.interfaces';

const OauthCache: PageConfig = {
  route: {
    path: '/oauth-cache',
  },
  label: 'TITLE',
  i18n: {
    keyPrefix: 'NAV_OAUTH_CACHE',
  },
  cards: {
    EnvInfo: {
      i18n: {
        keyPrefix: 'NAV_OAUTH_CACHE',
      },
      label: 'TITLE',
      fields: {
        app_id: {
          label: 'APP_ID',
          tooltip: 'APP_ID_TOOLTIP',
        },
      },
    },
  },
};

export default OauthCache;
