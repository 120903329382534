import GlobalPdfTemplatesPage from './GlobalPdfTemplates/page.config';
import OutboundEmailsReport from './OutboundEmailsReport/page.config';
import BounceReceiptsPage from './BounceReceipts/page.config';
import SuperContextualHelp from './SuperContextualHelp/page.config';
import DrawingImports from './DrawingImports/page.config';

export default {
  id: 'SuperArea',
  route: {
    path: '/v2/super/:page?',
  },
  i18n: {
    namespace: 'super-area',
  },
  permissions: {
    view: ['super_area_view'],
    edit: ['super_area_view'],
  },
  pages: {
    SuperArea: {
      label: '',
      cards: {},
    },
    GlobalPdfTemplatesPage,
    OutboundEmailsReport,
    BounceReceiptsPage,
    DrawingImports,
    SuperContextualHelp,
  },
};
