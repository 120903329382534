import React from 'react';
import { Button, Form } from '@procore/core-react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FormWrapper, FormButtonWrapper } from 'styles';
import type {
  IUrlLookupForm,
  IUrlLookupFormProps,
  IWorkflowUrlLookupPayload,
} from './interface';
import * as helpers from './helpers';
import JitWidget from 'components/JitWidget';

export const validateUrl = (url: string): string | undefined => {
  // check for valid url domain
  url = url.replace(/\s/g, ''); //delete all whitespace
  const urlDomain = url.match(helpers.regexGetDomain);
  if (urlDomain === null) {
    return 'This URL is invalid';
  } else if (
    !helpers.supportedProcoreDomains.find(
      (domain) => domain.url === urlDomain[0],
    )
  ) {
    return 'This Procore Domain is not supported';
  }
  if (
    url.match(helpers.regexGetProjectId) === null &&
    url.match(helpers.regexGetCompanyId) === null
  ) {
    // check for project ID
    return 'This URL is invalid (cannot parse Project ID)';
  }

  // check for valid item type. If regexGetItemType, check regexGetItemTypeCompany to see if this is a custom company tool url
  let itemType =
    url.match(helpers.regexGetItemType) === null
      ? url.match(helpers.regexGetItemTypeCompany)
      : url.match(helpers.regexGetItemType);
  if (itemType === null) {
    return 'This URL is invalid (cannot parse item type)';
  } else if (helpers.supportedWorkflowedItemTypes.indexOf(itemType[0]) == -1) {
    return 'The Workflow State tool does not support this Procore URL (Unsupported Item)';
  }

  // check for item ID
  const itemId = url.match(helpers.createRegexGetItemId(itemType[0]));
  if (itemId === null) {
    return 'This URL is invalid (cannot parse Item ID)';
  }

  // check for valid Sub Item Type / Id (if they exist)
  if (itemType !== null && itemId !== null) {
    const subItemType = url.match(
      helpers.createRegexGetSubItemType(itemType[0], itemId[0]),
    );
    if (
      subItemType !== null &&
      subItemType[0] !== '' &&
      helpers.supportedWorkflowedSubitemTypes.indexOf(subItemType[0]) == -1
    ) {
      return 'The Workflow State tool does not support this Procore URL (Unsupported Sub-Item)';
    }

    // Check for Sub Item Id
    if (subItemType !== null) {
      const subItemId = url.match(
        helpers.createRegexGetSubItemId(subItemType[0]),
      );
      if (subItemId === null) {
        return 'This URL is invalid (cannot parse Sub-Item ID)';
      }
    }
  }
};

export const UrlLookupForm = (props: IUrlLookupFormProps) => {
  const { t } = useTranslation(['workflow-state-tool'], {
    keyPrefix: 'URL_LOOKUP_FORM',
  });

  const doSearch = (values: IUrlLookupForm) => {
    values.url = values.url.replace(/\s/g, '');
    const projectIdMatch = values.url.match(helpers.regexGetProjectId);
    const companyIdMatch = values.url.match(helpers.regexGetCompanyId);
    const itemTypeMatch =
      values.url.match(helpers.regexGetItemType) === null
        ? values.url.match(helpers.regexGetItemTypeCompany)
        : values.url.match(helpers.regexGetItemType);

    const urlDomain = values.url.match(helpers.regexGetDomain);

    const env =
      (urlDomain &&
        helpers.supportedProcoreDomains.find(
          (domain) => domain.url === urlDomain[0],
        )?.env) ||
      '';

    const itemIdMatch = values.url.match(
      helpers.createRegexGetItemId(itemTypeMatch?.[0] || ''),
    );

    const subItemTypeMatch = values.url.match(
      helpers.createRegexGetSubItemType(
        itemTypeMatch?.[0] || '',
        itemIdMatch?.[0] || '',
      ),
    );

    const subItemIdMatch =
      subItemTypeMatch === null
        ? null
        : values.url.match(
            helpers.createRegexGetSubItemId(subItemTypeMatch[0]),
          );

    const payload: IWorkflowUrlLookupPayload = {
      projectId: projectIdMatch ? Number(projectIdMatch[0]) : null,
      companyId: companyIdMatch ? Number(companyIdMatch[0]) : null,
      itemType: itemTypeMatch
        ? helpers.convertItemTypeInUrl(itemTypeMatch[0])
        : '',
      itemId: itemIdMatch ? Number(itemIdMatch[0]) : -1,
      subItemType:
        subItemTypeMatch === null
          ? null
          : helpers.convertSubItemTypeInUrl(subItemTypeMatch[0]), // can be null
      subItemId: subItemIdMatch ? Number(subItemIdMatch[0]) : null, // can be null
      env: env,
      fullUrl: values.url,
    };

    props.action(payload);
  };

  return (
    <FormWrapper>
      <Form
        view="create"
        enableReinitialize={true}
        initialValues={{
          url: props.searchStr ?? '',
        }}
        validationSchema={yup.object().shape({
          url: yup.string().required(t('REQUIRED', { ns: 'common' })),
        })}
        onSubmit={(values) => doSearch(values)}
      >
        {(formProps: any) => {
          const { dirty, values, handleSubmit } = formProps;
          return (
            <Form.Form
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
            >
              <Form.Row>
                <Form.Text
                  autoFocus
                  name="url"
                  type="text"
                  data-qa="workflow-url"
                  label={t('URL_LOOKUP_LABEL')}
                  colWidth={6}
                  validate={validateUrl}
                />
                <FormButtonWrapper>
                  <Button
                    type="submit"
                    variant="primary"
                    data-qa="lookup-btn"
                    disabled={!dirty || !values.url}
                  >
                    {t('LOOKUP_BUTTON')}
                  </Button>
                </FormButtonWrapper>
              </Form.Row>
              {props.companyStatus && (
                <JitWidget
                  env={'PROD'}
                  companyId={props.companyStatus.id}
                  companyName={props.companyStatus.name}
                  isPinRequired={props.companyStatus.is_pin_required_for_jit}
                />
              )}
            </Form.Form>
          );
        }}
      </Form>
    </FormWrapper>
  );
};

export default UrlLookupForm;
