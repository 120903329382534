import React, { useContext } from 'react';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { Toast } from '@procore/core-react';
import { IpaAlertWrapper } from 'styles';

export const AlertComponent = () => {
  const alert = useContext(IpaAlertContext);
  if (alert.alertType === 'success') {
    if (!alert.keepVisible) {
      setTimeout(() => {
        alert.closeAlert();
      }, alert.timeout * 1000);
    }
  }
  if (alert.alertType !== 'none') {
    return (
      <IpaAlertWrapper
        timeout={alert.timeout}
        alertType={alert.alertType}
        keepVisible={alert.keepVisible}
      >
        <Toast variant={alert.alertType}>
          <Toast.Text>
            {alert.isHtml && (<span
              data-qa="banner-status-text"
              dangerouslySetInnerHTML={{ __html: alert.message }}
            ></span>)
            }
            {!alert.isHtml && (<span
              data-qa="banner-status-text"
            >{JSON.stringify(alert.message)}</span>)
            }
          </Toast.Text>
          {(alert.alertType === 'error' || alert.keepVisible) && (
            <Toast.Dismiss onClick={alert.closeAlert} data-qa="dismiss-btn" />
          )}
        </Toast>
      </IpaAlertWrapper>
    );
  } else {
    return null;
  }
};
