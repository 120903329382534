import { type PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'TITLE',
  route: {
    page: 'super_contextual_help',
  },
  i18n: {
    namespace: 'super-area',
    keyPrefix: 'GLOBAL_CONTEXTUAL_HELP',
  },
  cards: {},
};

export default pageConfig;
