import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyState } from '@procore/core-react';
import { Warning } from '@procore/core-icons';

export const NotFound = () => {
  const { t } = useTranslation(['common']);
  return (
    <EmptyState style={{ flexDirection: 'row' }}>
      <Warning size="md" />
      {t('PAGE_NOT_FOUND')}
    </EmptyState>
  );
};

export default NotFound;
