import React, { useContext } from 'react';
import { Dropdown, Link, Button } from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'contexts/AppContext';
import { can } from 'utils/permissions.helper';

interface CompanyLinksDropdownProps {
  companyId: number;
  userEmail?: string;
  isGds?: boolean;
  escalateAction?: (companyId: number, email: string) => void;
}

export const CompanyLinksDropdown = (props: CompanyLinksDropdownProps) => {
  const { t } = useTranslation(['company']);
  const { env, userConfig } = useContext(AppContext);

  const tableCompanyDropdown = [
    {
      url: `/v2/company/${props.companyId}/project-management?env=${env}`,
      title: t('SIDE_PANEL.PROJECT_MANAGEMENT_LINK'),
      qa: 'project-management',
    },
    {
      url: `/v2/company/${props.companyId}/pdf-template-config?env=${env}`,
      title: t('SIDE_PANEL.PDF_TEMPLATE_LINK'),
      qa: 'pdf-templates',
    },
    {
      url: `/v2/company/${props.companyId}/change-history?env=${env}`,
      title: t('SIDE_PANEL.CHANGE_HISTORY_LINK'),
      qa: 'change-history',
    },
    {
      url: `/v2/company/${props.companyId}/correspondence-types?env=${env}`,
      title: t('SIDE_PANEL.CORRESPONDENCE_TYPES_LINK'),
      qa: 'correspondence-types',
    },
    can({ user: userConfig }, ['edit_erp_support_tools']) && {
      url: `/v2/company/${props.companyId}/erp-deactivations?env=${env}`,
      title: t('SIDE_PANEL.ERP_DEACTIVATIONS_LINK'),
      qa: 'erp-deactivations',
    },
    can({ user: userConfig }, ['cs_view']) && {
      url: `/v2/company-directory/${props.companyId}?env=${env}`,
      title: t('SIDE_PANEL.COMPANY_DIRECTORY_LINK'),
      qa: 'company-directory',
    },
  ];

  return (
    <Dropdown size="sm" data-qa="company-links-dropdown">
      {tableCompanyDropdown
        .sort((a, b) => (a.title < b.title ? -1 : 1))
        .map((item) => (
          <Dropdown.Item item={item.qa} key={item.qa} data-qa={item.qa}>
            <Link href={item.url}>{item.title}</Link>
          </Dropdown.Item>
        ))}
      {props.isGds && (
        <Dropdown.Item>
          <Button
            size="sm"
            onClick={(e) => {
              props.escalateAction(props.companyId, props.userEmail);
            }}
            data-qa="dropdown-escalate-btn"
          >
            {t('ESCALATE', { ns: 'jit' })}
          </Button>
        </Dropdown.Item>
      )}
    </Dropdown>
  );
};

export default CompanyLinksDropdown;
