import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Box, Title, H2, Form, Button, spacing } from '@procore/core-react';
import { FormWrapper, FormButtonWrapper } from 'styles';
import { validateUrl } from 'pages/WorkflowStateTool/UrlLookup';
import * as helpers from 'pages/WorkflowStateTool/helpers';
import type {
  IUrlLookupForm,
  IWorkflowUrlLookupPayload,
} from 'pages/WorkflowStateTool/interface';

const WorkflowStateTool = () => {
  const { t } = useTranslation('homepage');
  const { t: tWorkFlowStateTool } = useTranslation(['workflow-state-tool'], {
    keyPrefix: 'URL_LOOKUP_FORM',
  });
  const navigate = useNavigate();

  const buildPayload = async (values: IUrlLookupForm) => {
    values.url = values.url.replace(/\s/g, '');
    const projectIdMatch = values.url.match(helpers.regexGetProjectId);
    const companyIdMatch = values.url.match(helpers.regexGetCompanyId);
    const itemTypeMatch =
      values.url.match(helpers.regexGetItemType) === null
        ? values.url.match(helpers.regexGetItemTypeCompany)
        : values.url.match(helpers.regexGetItemType);

    const urlDomain = values.url.match(helpers.regexGetDomain);

    const env =
      (urlDomain &&
        helpers.supportedProcoreDomains.find(
          (domain) => domain.url === urlDomain[0],
        )?.env) ||
      '';

    const itemIdMatch = values.url.match(
      helpers.createRegexGetItemId(itemTypeMatch?.[0] || ''),
    );

    const subItemTypeMatch = values.url.match(
      helpers.createRegexGetSubItemType(
        itemTypeMatch?.[0] || '',
        itemIdMatch?.[0] || '',
      ),
    );

    const subItemIdMatch =
      subItemTypeMatch === null
        ? null
        : values.url.match(
            helpers.createRegexGetSubItemId(subItemTypeMatch[0]),
          );

    const payload: IWorkflowUrlLookupPayload = {
      projectId: projectIdMatch ? Number(projectIdMatch[0]) : null,
      companyId: companyIdMatch ? Number(companyIdMatch[0]) : null,
      itemType: itemTypeMatch
        ? helpers.convertItemTypeInUrl(itemTypeMatch[0])
        : '',
      itemId: itemIdMatch ? Number(itemIdMatch[0]) : -1,
      subItemType:
        subItemTypeMatch === null
          ? null
          : helpers.convertSubItemTypeInUrl(subItemTypeMatch[0]), // can be null
      subItemId: subItemIdMatch ? Number(subItemIdMatch[0]) : null, // can be null
      env: env,
      fullUrl: values.url,
    };
    localStorage.setItem('homepage-wst-payload', JSON.stringify(payload));
    navigate(`/v2/workflow-state-tool`);
  };

  return (
    <Box data-qa="homepage-workflow-state-tool">
      <Title>
        <Title.Text>
          <H2>{t('MODULES.WST')}</H2>
        </Title.Text>
      </Title>
      <FormWrapper marginTop={`${spacing.md}px`}>
        <Form
          view="create"
          enableReinitialize={true}
          initialValues={{
            url: '',
          }}
          validationSchema={yup.object().shape({
            url: yup.string().required(t('REQUIRED', { ns: 'common' })),
          })}
          onSubmit={(values) => buildPayload(values)}
        >
          {(formProps: any) => {
            const { dirty, values, handleSubmit } = formProps;
            return (
              <Form.Form
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
              >
                <Form.Row>
                  <Form.Text
                    autoFocus
                    name="url"
                    type="text"
                    data-qa="wst-workflow-url"
                    label={tWorkFlowStateTool('URL_LOOKUP_LABEL')}
                    colStart={1}
                    colWidth={11}
                    validate={validateUrl}
                  />
                  <FormButtonWrapper>
                    <Button
                      type="submit"
                      variant="primary"
                      data-qa="wst-lookup-btn"
                      disabled={!dirty || !values.url}
                    >
                      {tWorkFlowStateTool('LOOKUP_BUTTON')}
                    </Button>
                  </FormButtonWrapper>
                </Form.Row>
              </Form.Form>
            );
          }}
        </Form>
      </FormWrapper>
    </Box>
  );
};

export default WorkflowStateTool;
