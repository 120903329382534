import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

export const LegacyRouteRewriter = (props: { to: string }) => {
  const params = useParams();

  const toUrl = props.to.replace(/:(\w+)/g, (match, key) => {
    return params[key];
  });

  return <Navigate replace to={toUrl} />;
};

export const legacyRoutes = [
  {
    path: '/company',
    to: '/search/company',
  },
  {
    path: '/company/:companyId',
    to: '/v2/company/:companyId',
  },
  {
    path: '/customer-success',
    to: '/search/user',
  },
  {
    path: '/customer-success/workflow-state-tool',
    to: '/v2/workflow-state-tool',
  },
  {
    path: '/customer-success/custom-reports-tabs',
    to: '/v2/custom-reports-tabs',
  },
  {
    path: '/customer-success/project-management/company/:companyId',
    to: '/v2/company/:companyId/project-management',
  },
  {
    path: '/customer-success/:page/company/:companyId/contact/:contactId/login/:loginId',
    to: '/v2/company/:companyId/contact/:contactId/login/:loginId/contact-info',
  },
  {
    path: '/payments',
    to: '/v2/payments',
  },
  {
    path: '/payments/business-entity/:entityId/:page',
    to: '/v2/business-entity/:entityId?tab=:page',
  },
  {
    path: '/su',
    to: '/v2/super-user',
  },
];
