import React, { useState, useCallback, useEffect } from 'react';
import { Button, Spinner } from '@procore/core-react';
import { useTranslation, type TFunction } from 'react-i18next';
import { getApi } from 'utils/api';
import { JITApi } from '@procore/ipa-nt-api-client-ts';
import type { JitSessionDto } from '@procore/ipa-nt-api-client-ts';
import { TableComponent } from 'components/Table';
import type { TableHeader } from 'components/Table/table.interface';
import { JitExtendModal } from 'components/JitExtendModal';
import { JitDeescalateModal } from 'components/JitDeescalateModal';
import Countdown from 'components/Countdown';
import CompanyLink from 'components/CompanyLink';

interface JitSessionListProps {
  env: string;
  latestEscalation: Date | undefined; // signals to (re)load the session list
  setSuccessMessage(message: string): void;
  setErrorMessage(message: string): void;
}

export const getCompanyLink = (session: JitSessionDto, env: string) => {
  let urlOverride: string = undefined;
  if (session.accessLevel === 'admin-erp') {
    urlOverride = `/${session.companyId}/company/erp_integrations/commitments/ready_to_export`;
  } else if (session.redirectUrl !== null && session.redirectUrl !== '') {
    urlOverride = `/${session.redirectUrl}`;
  }

  return (
    <CompanyLink
      hideLabel={true}
      companyId={Number(session.companyId)}
      companyName={session.companyName}
      env={env}
      data-qa={`jit-session-link-${session.id}`}
      url={urlOverride}
    />
  );
};

export const expiresCell = (session: JitSessionDto, t: TFunction) => {
  const diff = Math.round((+new Date(session.expiresAt) - +new Date()) / 1000);
  const deescalatingText = t('DEESCALATING', { ns: 'jit' });
  return (
    <span>
      {diff > 0 ? (
        <Countdown time_left={diff} expired_msg={deescalatingText} />
      ) : (
        deescalatingText
      )}
    </span>
  );
};

export const JitSessionList = (props: JitSessionListProps) => {
  const { t } = useTranslation(['jit']);
  const [loading, setLoading] = useState<boolean>(false);
  const [jitSessions, setJitSessions] = useState<JitSessionDto[]>([]);

  const [deescalateModalOpen, setDeescalateModalOpen] =
    useState<boolean>(false);
  const [deescalateSessionData, setDeescalateSessionData] =
    useState<JitSessionDto | null>(null);

  const deescalateModalClose = useCallback(() => {
    setDeescalateSessionData(null);
    setDeescalateModalOpen(false);
  }, []);
  const openDeescalateModal = useCallback((session: JitSessionDto) => {
    setDeescalateSessionData(session);
    setDeescalateModalOpen(true);
  }, []);
  const deescalateFinished = useCallback(
    (success: boolean, session: JitSessionDto) => {
      setDeescalateModalOpen(false);
      setDeescalateSessionData(null);
      loadSessionList();
      success &&
        props.setSuccessMessage(
          t('DEESCALATE_SUCCESS', {
            accessLevel: session.accessLevel,
            companyName: session.companyName,
          }),
        );
    },
    [],
  );

  const [extendModalOpen, setExtendModalOpen] = useState<boolean>(false);
  const [extendSessionData, setExtendSessionData] =
    useState<JitSessionDto | null>(null);

  const extendModalClose = useCallback(() => {
    setExtendSessionData(null);
    setExtendModalOpen(false);
  }, []);
  const openExtendModal = useCallback((session: JitSessionDto) => {
    setExtendSessionData(session);
    setExtendModalOpen(true);
  }, []);

  const extendFinished = useCallback(
    (success: boolean, session: JitSessionDto) => {
      setExtendModalOpen(false);
      setExtendSessionData(null);
      loadSessionList();
      success &&
        props.setSuccessMessage(
          t('EXTEND_SUCCESS', {
            accessLevel: session.accessLevel,
            companyName: session.companyName,
          }),
        );
    },
    [],
  );

  const companyCell = (session: JitSessionDto) => {
    return (
      <div>
        {getCompanyLink(session, props.env)}

        {session.accessLevel === 'impersonate' && (
          <span>
            {' '}
            (as <u>{session.impersonateEmail}</u>)
          </span>
        )}
      </div>
    );
  };
  const actionButtons = (session: JitSessionDto) => {
    return (
      <div>
        <Button onClick={() => openDeescalateModal(session)} size="sm">
          {t('LABEL_DEESCALATE')}
        </Button>{' '}
        &nbsp;
        <Button
          onClick={() => openExtendModal(session)}
          size="sm"
          variant="secondary"
        >
          {t('LABEL_EXTEND')}
        </Button>
      </div>
    );
  };

  const tableHeaders: TableHeader[] = [
    {
      title: t('COMPANY_NAME', { ns: 'common' }),
      node: 'companyName',
      display_func: companyCell,
      is_sortable: false,
      column_width: 35,
    },
    {
      title: t('LABEL_ACCESS_LEVEL'),
      node: 'accessLevel',
      is_sortable: false,
      is_text: true,
      column_width: 20,
    },
    {
      title: t('LABEL_EXPIRES'),
      node: 'expiresAt',
      display_func: (session) => expiresCell(session, t),
      is_sortable: false,
      column_width: 20,
    },
    {
      title: '',
      node: 'id',
      is_sortable: false,
      display_func: actionButtons,
      column_width: 25,
    },
  ];

  const loadSessionList = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getApi(JITApi).jitControllerGetActiveSessionsForUser(
        props.env,
        {
          headers: {
            'Cache-Control': 'no-cache',
          },
        },
      );
      setLoading(false);
      if (result.data) {
        setJitSessions(result.data);
      }
    } catch (error: any) {
      const msg = t('LOAD_SESSIONS_ERROR', {
        ns: 'jit',
        env: props.env,
        reason: error.response?.data?.message || error.message,
      });
      props.setErrorMessage && props.setErrorMessage(msg);
      return null;
    }
  }, [props.env]);

  useEffect(() => {
    loadSessionList();
  }, [props.latestEscalation]);

  return (
    <div>
      {loading && <Spinner size="md" />}
      {!loading && (
        <TableComponent
          headers={tableHeaders}
          data={jitSessions}
          default_sort_column="company_name"
          default_sort_direction="asc"
          show_no_data_message={true}
          hide_pagination={true}
          no_data_message="No sessions available"
          fixed_table={true}
          data_qa="jit-table"
        />
      )}
      <JitExtendModal
        session={extendSessionData}
        isOpen={extendModalOpen}
        closeModal={extendModalClose}
        setParentErrorMessage={props.setErrorMessage}
        doneCallback={extendFinished}
      />
      <JitDeescalateModal
        session={deescalateSessionData}
        isOpen={deescalateModalOpen}
        closeModal={deescalateModalClose}
        setParentErrorMessage={props.setErrorMessage}
        doneCallback={deescalateFinished}
      />
    </div>
  );
};
