import React, { useEffect, useContext } from 'react';
import { AppContext } from 'contexts/AppContext';
import { AnalyticEvents, type IPAEvent } from 'pages/analytic-events.service';
import { useLocation } from 'react-router-dom';

const stripIdsRegex = new RegExp('(/\\d+)', 'g');

const queue: IPAEvent[] = [];

export const PageLoadReporter: React.FC = () => {
  const location = useLocation();
  const appContext = useContext(AppContext);
  let processQueueTimer: NodeJS.Timer;

  const stripIdentifiers = (url: string) => {
    return url.replaceAll(stripIdsRegex, '/:id');
  };

  const reportPageLoad = (url: string) => {
    queue.push({
      event: 'page_load',
      props: {
        url: stripIdentifiers(url),
      },
    });
  };

  const processQueue = async () => {
    if (!appContext.authHeaderPresent) {
      return;
    }

    while (queue.length > 0) {
      const item = queue.shift();
      if (item) {
        AnalyticEvents.postIpaEvent(item);
      }
    }
  };

  // Start/stop queue processing based on auth header presence
  useEffect(() => {
    if (processQueueTimer && !appContext.authHeaderPresent) {
      clearInterval(processQueueTimer);
    }

    if (!processQueueTimer && appContext.authHeaderPresent) {
      processQueueTimer = setInterval(processQueue, 500);
    }
  }, [appContext.authHeaderPresent]);

  useEffect(() => {
    reportPageLoad(location.pathname);
  }, [location]);

  return <React.Fragment />;
};
