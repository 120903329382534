import React, { useContext, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import { AppContext } from 'contexts/AppContext';

export const AxiosConfigurator: React.FC = () => {
  const appContext = useContext(AppContext);
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    const elixirMode = oktaAuth.options.clientId === 'elixir';
    if (elixirMode) {
      setTokenFromElixir();
    } else {
      setTokenFromJwt();
    }
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  const setTokenFromElixir = () => {
    const token = authState?.accessToken?.accessToken;
    if (token) {
      setTokenHeader(token);
    }
  };

  const setTokenFromJwt = () => {
    const token = oktaAuth.getOrRenewAccessToken();
    token.then((accessToken) => {
      setTokenHeader(accessToken);
      oktaAuth.tokenManager.on('renewed', function (key, newToken) {
        if (key == 'accessToken' && 'accessToken' in newToken) {
          setTokenHeader(newToken.accessToken);
        }
      });
    });
  };

  const setTokenHeader = (token: string) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    appContext.updateAuthHeaderPresent(true);
  };

  return <React.Fragment />;
};
