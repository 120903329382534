import React, { useContext, useState } from 'react';
import {
  Page,
  Card,
  Button,
  Title,
  H1,
  InfoBanner,
  Banner,
  spacing,
  Form,
} from '@procore/core-react';
import { FormWrapper } from 'styles';
import { useTranslation } from 'react-i18next';
import { DevPortalApi } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import { PageConfig } from 'pages/page-config.service';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import * as yup from 'yup';

export const OauthSecretRotationsPage = () => {
  const { t: tCommon } = useTranslation(['common']);
  const pageConfig = PageConfig.get(tCommon, 'DevPortal.OauthSecretRotations');
  const [developerAppId, setDeveloperAppId] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const alert = useContext(IpaAlertContext);

  const envOptions = [
    {
      label: pageConfig.translate('ENV_INFO.PROD_ENV'),
      id: 'production',
    },
    { label: pageConfig.translate('ENV_INFO.SANDBOX_ENV'), id: 'sandbox' },
    {
      label: pageConfig.translate('ENV_INFO.MONTHLY_SANDBOX_ENV'),
      id: 'monthly_sandbox',
    },
  ];

  const handleSubmit = async (values: {
    developerAppId: string;
    selectedEnv: { label: string; id: string };
  }) => {
    try {
      alert.closeAlert();
      setIsLoading(true);

      const api = getApi(DevPortalApi);
      const response: any = await api.devPortalControllerRotateSecret(
        values.developerAppId,
        values.selectedEnv.id,
      );
      const data = response.data;
      const status = data.status || response.status;

      if (status === 201) {
        alert.success(pageConfig.translate('ENV_INFO.SUCCESS_MESSAGE'));
      } else if (status === 422) {
        alert.error(
          pageConfig.translate('ENV_INFO.UNPROCESSABLE_ENTITY', {
            error: JSON.stringify(data.error),
          }),
        );
      } else if (status === 401) {
        alert.error(pageConfig.translate('ENV_INFO.UNAUTHORIZED_MESSAGE'));
      } else if (status === 404) {
        alert.error(pageConfig.translate('ENV_INFO.NOT_FOUND_MESSAGE'));
      } else {
        alert.error(
          pageConfig.translate('ENV_INFO.UNKNOWN_ERROR_MESSAGE', {
            status: status,
            data: JSON.stringify(data),
          }),
        );
      }
    } catch (error: any) {
      alert.error(
        pageConfig.translate('ENV_INFO.ERROR_MESSAGE', {
          error: error.message || error,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Page>
      <Page.Main>
        <Card>
          <InfoBanner>
            <Banner.Content>
              <Banner.Body data-qa="banner-status-text">
                {pageConfig.translate('INFO_MESSAGE')}
              </Banner.Body>
            </Banner.Content>
          </InfoBanner>
        </Card>
        <Card style={{ margin: spacing.md, padding: spacing.md }}>
          <Page.Header transparent={true}>
            <Page.Title>
              <Title>
                <Title.Text>
                  <H1>{pageConfig.label}</H1>
                </Title.Text>
              </Title>
            </Page.Title>
          </Page.Header>
          <Page.Body>
            <FormWrapper>
              <Form
                view="create"
                enableReinitialize={true}
                initialValues={{
                  developerAppId: '',
                  selectedEnv: { label: '', id: '' },
                }}
                validationSchema={yup.object().shape({
                  developerAppId: yup.string(),
                  selectedEnv: yup.object(),
                })}
                onSubmit={(values) => handleSubmit(values)}
              >
                <Form.Form>
                  <Page.Column>
                    <Form.Text
                      value={developerAppId}
                      name="developerAppId"
                      onChange={(e) => setDeveloperAppId(e.target.value)}
                      placeholder={pageConfig.translate('ENV_INFO.APP_ID')}
                      data-qa="developer-app-id-field"
                      style={{
                        width: '30%',
                        marginBottom: '10px',
                      }}
                    />
                    <Form.Select
                      name="selectedEnv"
                      data-qa="env-select"
                      label={tCommon('ENVIRONMENT')}
                      onSearch={false}
                      style={{
                        width: '28%',
                        marginBottom: '5px',
                      }}
                      options={envOptions}
                    />
                    <Button
                      type="submit"
                      variant="primary"
                      data-qa="rotate-btn"
                      disabled={isLoading}
                      style={{
                        marginTop: '5px',
                        marginBottom: '10px',
                      }}
                    >
                      {pageConfig.translate('ENV_INFO.BUTTON_NAME')}
                    </Button>
                  </Page.Column>
                </Form.Form>
              </Form>
            </FormWrapper>
          </Page.Body>
        </Card>
      </Page.Main>
    </Page>
  );
};

export default OauthSecretRotationsPage;
