import React, { useState } from 'react';
import type { ModalWidth } from '@procore/core-react/dist/Modal/Modal.types';
import { Form, Button, Page, Title, H2 } from '@procore/core-react';
import { ChevronDown, ChevronRight } from '@procore/core-icons';
import { useTranslation } from 'react-i18next';
import { ipaAppStyles } from 'styles';
import DataEditModal from 'components/DataEditModal';
import { chunks } from 'utils/utils';
import type {
  IEditableCard,
  IEditbleCardField,
  INoEditChunk,
} from './interfaces';
import { StyledPageColumn } from 'styles';

// Re-export interfaces for use in other components
export * from './interfaces';

export interface EditableCardProps {
  modalWidth?: ModalWidth;
  card: IEditableCard;
  addEntry?: React.ComponentType;
  isCollapsible?: boolean;
  validationSchema?: Record<string, any>;
}

export const EditableCard = (props: EditableCardProps) => {
  const {
    card: { i18n: t, fields, save, canEdit },
    addEntry: AddEntry,
    validationSchema,
  } = props;

  const { t: tCommon } = useTranslation(['common']);
  const [modalOpen, setModalOpen] = useState(false);
  const fieldsPerRow =
    props.card.fieldsPerRow && props.card.fieldsPerRow <= 4
      ? props.card.fieldsPerRow
      : 2;
  const [collapsed, setCollapsed] = useState<boolean>(false);

  // Use form input names in format `datasourceId:fieldName`
  const getFieldName = (field: IEditbleCardField): string =>
    `${field.source}:${field.name}`;

  const cardData = fields.reduce((acc, field) => {
    const value = field.transformValueBefore
      ? field.transformValueBefore(field.value)
      : field.value;
    return {
      ...acc,
      [getFieldName(field)]: value,
    };
  }, {});

  const editCardData = fields.reduce((acc, field) => {
    let value = field.transformValueBefore
      ? field.transformValueBefore(field.value)
      : field.value;
    value = field.editableValue || value;

    return {
      ...acc,
      [getFieldName(field)]: value,
    };
  }, {});

  const formChildrenRender = (field: IEditbleCardField) => {
    return field.formViews?.map((formView, index) => {
      return (
        <React.Fragment key={index}>
          {formView === 'read' && <field.element.Read />}
          {formView === 'update' && <field.element.Update />}
          {formView === 'create' && <field.element.Create />}
        </React.Fragment>
      );
    });
  };

  const createFormElement = (
    field: IEditbleCardField,
    view: string = 'view',
  ) => {
    const fieldName = getFieldName(field);

    if (field.elementFactory) {
      return field.elementFactory({
        ...field,
        name: fieldName,
      });
    } else {
      const element = React.createElement(
        field.element,
        {
          key: fieldName,
          'data-qa': `input-${field.name}`,
          name: fieldName,
          label: field.label,
          type: field.dataType,
          tooltip: field.tooltip,
          disabled: field.editable === undefined ? false : !field.editable,
        },
        formChildrenRender(field),
      );
      return field.noEditChunk && view === 'edit' ? (
        <div>
          {element} <span> - {field.noEditChunk}</span>
        </div>
      ) : (
        element
      );
    }
  };

  const formFields = fields
    .filter((field) => !field.hideOnRead)
    .map((field) => createFormElement(field));

  const editFormFields = fields
    .filter((field) => !field.hideOnEdit)
    .map((field) => createFormElement(field, 'edit'));

  // Map field to display name for the comparison grid
  const dataMap = fields.map((field) => ({
    display_name: field.label,
    name: getFieldName(field),
  }));

  const getColWidth = () => {
    if (fieldsPerRow === 1) {
      return {
        tabletSm: 12,
        tabletMd: 12,
        tabletLg: 12,
      };
    } else {
      return {
        tabletSm: 12,
        tabletMd: 12 / fieldsPerRow,
        tabletLg: 12 / fieldsPerRow,
      };
    }
  };

  const createForm = (fields: any) => (
    <Page.Grid>
      {Array.from(chunks(fields, fieldsPerRow)).map(
        (row: any, rowKey: number) => (
          <Page.Row key={rowKey}>
            {row.map((field: IEditbleCardField, colKey: number) => (
              <StyledPageColumn colWidth={getColWidth() as any} key={colKey}>
                {field}
              </StyledPageColumn>
            ))}
          </Page.Row>
        ),
      )}
    </Page.Grid>
  );

  const viewForm = createForm(formFields);
  const editForm = createForm(editFormFields);

  const _save = (values: any, reason?: string, changedKeys?: string[]) => {
    fields.forEach((field) => {
      const fieldName = getFieldName(field);
      if (
        field.transformValueAfter !== undefined &&
        values[fieldName] !== undefined
      ) {
        values[fieldName] = field.transformValueAfter(values[fieldName]);
      }
    });

    return save(
      props.card,
      getValuesByDatasource(values, changedKeys),
      reason,
      changedKeys,
    );
  };

  const getNoEditChunks = () => {
    let chunks = {} as INoEditChunk;
    const temp = fields.filter((field) => field.noEditChunk);

    temp.forEach((field) => {
      chunks[`${field.source}:${field.name}`] = field.noEditChunk;
    });

    return chunks;
  };

  return (
    <React.Fragment>
      <Title style={ipaAppStyles.titleMargin}>
        {props.isCollapsible && (
          <Title.Assets>
            <Button variant="tertiary" onClick={() => setCollapsed(!collapsed)}>
              {!collapsed ? <ChevronDown /> : <ChevronRight />}
            </Button>
          </Title.Assets>
        )}
        <Title.Text>
          <H2>{t('TITLE')}</H2>
        </Title.Text>
        <Title.Actions>
          {canEdit && AddEntry && <AddEntry />}
          {canEdit && (
            <Button
              variant="secondary"
              onClick={() => setModalOpen(true)}
              data-qa={`edit-btn-${props.card.id}`}
            >
              {tCommon('EDIT')}
            </Button>
          )}
        </Title.Actions>
      </Title>

      {!collapsed && (
        <Form enableReinitialize={true} view="read" initialValues={cardData}>
          <div data-qa={`form-view-${props.card.id}`}>{viewForm}</div>
        </Form>
      )}

      <DataEditModal
        data={{ ...editCardData }}
        show={modalOpen}
        formView={'update'}
        close={() => setModalOpen(false)}
        save={_save}
        dataMap={dataMap}
        editReason={tCommon('PROVIDE_REASON')}
        changesTitle={tCommon('CHANGES_MADE')}
        editTitle={t('EDIT_MODAL_TITLE')}
        modalCopy={props.card.modalCopy}
        modalWidth={props.modalWidth ?? 'md'}
        noEditChunks={getNoEditChunks()}
        validationSchema={validationSchema}
      >
        <div data-qa={`form-edit-${props.card.id}`}>{editForm}</div>
      </DataEditModal>
    </React.Fragment>
  );
};

const getValuesByDatasource = (values: any, changedKeys?: string[]) => {
  return Object.entries(values).reduce(
    (acc: { [key: string]: object }, field) => {
      const [fieldKey, fieldValue] = field;
      const [dataSource, fieldName] = fieldKey.split(':');

      return changedKeys?.includes(fieldKey)
        ? {
            ...acc,
            [dataSource]: {
              ...acc[dataSource],
              [fieldName]: fieldValue,
            },
          }
        : acc;
    },
    {},
  );
};
