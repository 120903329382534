import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Select, Checkbox, Button, Grid, FlexList } from '@procore/core-react';
import { Check, Error } from '@procore/core-icons';
import { TableBackendComponent } from 'components/TableBackend';
import type { TableHeader } from 'components/Table/table.interface';
import type { SelectOption } from 'components/JitEscalateTearsheet/form.interface';
import { useTranslation } from 'react-i18next';
import { styles } from './styles';
import { CompanySettingsApi, JITApi } from '@procore/ipa-nt-api-client-ts';
import type {
  JitCompanySettingsDto,
  JitSessionDto,
} from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import JitEscalateModal from 'components/JitEscalateModal';
import type { JitEscalateFormValues } from 'components/JitEscalateModal';
import type { Selection } from '@procore/core-react/dist/MenuImperative/MenuImperative.types';
import { getDateTime } from 'utils/datetime';
import { AppContext } from 'contexts/AppContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import ConfirmModal from 'components/ConfirmModal';
import DataDisplayModal from 'components/DataDisplayModal';
import { JIT_ACCESS_LEVELS } from 'common/constants';
import { can } from 'utils/permissions.helper';
import { getBaseUrl } from 'utils/utils';

export interface JitAuditLogProps {
  dateRange: number | undefined;
  pageSize: number | undefined;
  jitSessions: JitSessionDto[] | undefined;
  total: number | undefined;
  // notify parent component to refresh data
  refresh?: () => void;
}

export const JitAuditLog = (props: JitAuditLogProps) => {
  const { t: tCommon } = useTranslation(['common']);
  const { t } = useTranslation(['jit-audit-log', 'jit']);
  const alert = useContext(IpaAlertContext);
  const { userConfig } = useContext(AppContext);
  const [sessions, setSessions] = useState<JitSessionDto[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState<JitSessionDto>(
    {} as JitSessionDto,
  );
  const [escalatedOnly, setEscalatedOnly] = useState(false);
  const [userOnly, setUserOnly] = useState(true);
  const [includeMonthly, setIncludeMonthly] = useState(false);
  const [numberOfDays, setNumberOfDays] = useState<number>(7);
  const [pageSize, setPageSize] = useState(10);
  const [currPage, setCurrPage] = useState(1);
  const [sort, setSort] = useState<string>('startedAt');
  const [sortDirection, setSortDirection] = useState<string>('desc');
  const [searchStr, setSearchStr] = useState<string>('');
  const [escalateModalOpen, setEscalateModalOpen] = useState(false);
  const [selectedEscalation, setSelectedEscalation] = useState<JitSessionDto>(
    {} as JitSessionDto,
  );
  const [isStrategicAccount, setIsStrategicAccount] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const getDateRangeLabel = (id: number) => {
    return t('DATE_RANGE_LABEL', { days: id, ns: 'jit-audit-log' });
  };

  const dateRanges: SelectOption[] = [
    { id: '7', label: `${getDateRangeLabel(7)}` },
    { id: '30', label: `${getDateRangeLabel(30)}` },
    { id: '90', label: `${getDateRangeLabel(90)}` },
  ];

  const getSelectLabel = () => {
    const selected = dateRanges.find((d) => d.id === numberOfDays.toString());
    return selected ? selected.label : dateRanges[0].label;
  };

  const displayDeescalationStatus = (item: JitSessionDto, node: string) => {
    return item[node as keyof JitSessionDto] ? (
      <Check size="sm" style={styles.checkIcon} />
    ) : (
      <Error size="sm" style={styles.errorIcon} />
    );
  };

  const openReescalateModal = async (item: JitSessionDto) => {
    setEscalateModalOpen(true);
    setSelectedEscalation(item);
    const companyId = Number(item.companyId);
    if (companyId) {
      const result = await getApi(
        CompanySettingsApi,
      ).companySettingsControllerCheckStrategicAccount(companyId);
      setIsStrategicAccount(result.data);
    }
  };

  const closeEscalateModal = () => {
    setEscalateModalOpen(false);
  };

  const escalate = async (values: JitEscalateFormValues) => {
    try {
      // setLoading(true);
      const result = await getApi(JITApi).jitControllerCreateSession({
        env: values.env,
        companyId: Number(values.company_id),
        accessLevel: values.access_level!.id as any,
        category: values.category!.id,
        reason: values.detail,
        impersonateEmail: values.email,
        pin: values.pin,
        salesForceCaseNumber: values.salesforce_case_number,
        escalationLength: Number(values.escalation_length.id),
      });
      // setLoading(false);

      if (result.data) {
        alert.success(t('RE_ESCALATE_SUCCESS', { ns: 'jit-audit-log' }));
      }

      if (values.open_new_window) {
        window.open(
          `${getBaseUrl(values.env, 'procore', +values.company_id)}/${
            values.company_id
          }/company/home/list`,
          '_blank',
        );
        return false;
      }

      await loadJitSessions();
      if (props.refresh) {
        props.refresh();
      }
    } catch (e: any) {
      alert.error(e);
    } finally {
      setEscalateModalOpen(false);
    }
  };

  const terminateSession = async () => {
    const companyId = Number(selectedSession.companyId);
    if (companyId) {
      try {
        await getApi(JITApi).jitControllerTerminateSession(selectedSession.id);
        alert.success(t('TERMINATE_SUCCESS', { ns: 'jit-audit-log' }));

        await loadJitSessions();
        if (props.refresh) {
          props.refresh();
        }
      } catch (e: any) {
        alert.error(t('TERMINATE_FAILED', { ns: 'jit-audit-log' }));
      }
    }
  };

  const openTerminateConfirmModal = (item: JitSessionDto) => {
    setSelectedSession(item);
    setOpenConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const isJitSessionActive = (sessionDto: JitSessionDto) => {
    return !sessionDto.endedAt;
  };

  const displayActionCell = (item: JitSessionDto) => (
    <FlexList size="xs">
      <Button
        size="sm"
        onClick={() => {
          setSelectedSession(item);
          setDetailsModalOpen(true);
        }}
        data-qa="details-btn"
      >
        {tCommon('DETAILS')}
      </Button>
      {showReescalateButton(item) && (
        <Button
          size="sm"
          onClick={() => openReescalateModal(item)}
          data-qa="reescalate-btn"
        >
          {t('RE_ESCALATE', { ns: 'jit-audit-log' })}
        </Button>
      )}
      {showTerminateButton(item) && (
        <Button
          size="sm"
          onClick={() => openTerminateConfirmModal(item)}
          data-qa={'jit-terminate-button'}
        >
          {t('TERMINATE', { ns: 'jit-audit-log' })}
        </Button>
      )}
    </FlexList>
  );

  const accessLevels = JIT_ACCESS_LEVELS.map((level) => {
    return {
      ...level,
      label: t(level.label, { ns: 'jit' }),
    };
  });

  const tableHeaders: TableHeader[] = [
    {
      title: tCommon('START'),
      node: 'startedAt',
      is_sortable: true,
      is_datetime: true,
      column_width: 15,
    },
    {
      title: tCommon('COMPANY_NAME'),
      node: 'companyName',
      is_sortable: true,
      is_text: true,
      column_width: 15,
    },
    {
      title: tCommon('COMPANY_ID'),
      node: 'companyId',
      is_sortable: true,
      is_text: true,
      column_width: 10,
    },
    {
      title: t('SALESFORCE_CASE_NUMBER', { ns: 'jit-audit-log' }),
      node: 'salesForceCaseNumber',
      is_sortable: true,
      is_text: true,
      column_width: 5,
    },
    {
      title: tCommon('EMAIL'),
      node: 'userEmail',
      is_sortable: true,
      is_text: true,
      column_width: 20,
    },
    {
      title: tCommon('ENV'),
      node: 'env',
      is_sortable: true,
      is_text: true,
      column_width: 10,
    },
    {
      title: t('ACCESS_LEVEL', { ns: 'jit-audit-log' }),
      node: 'accessLevel',
      is_sortable: true,
      display_func: (item: JitSessionDto) => {
        return (
          <div>
            {accessLevels.find((a) => a.id === item.accessLevel)?.label}
          </div>
        );
      },
      column_width: 10,
    },
    {
      title: t('DE_ESCALATED', { ns: 'jit-audit-log' }),
      node: 'endedAt',
      is_sortable: true,
      display_func: displayDeescalationStatus,
      column_width: 10,
    },
    {
      title: '',
      node: null as any,
      is_sortable: false,
      display_func: displayActionCell,
      column_width: 5,
    },
  ];

  const sortJitSessions = (col: string, direction: string) => {
    setCurrPage(1);
    setSort(col);
    setSortDirection(direction);
  };

  const changePage = async (page: number) => {
    setCurrPage(page);
  };

  const changePageSize = async (size: number) => {
    setPageSize(size);
  };

  const changeDateRange = async (v: Selection) => {
    setCurrPage(1);
    setNumberOfDays(+v.item.id);
  };

  const doSearch = async (value: string) => {
    setCurrPage(1);
    setSearchStr(value);
  };

  const showReescalateButton = (jitSession: JitSessionDto) => {
    // can only re-escalate user's own sessions
    return !isJitSessionActive(jitSession) &&
      (userConfig.user?.username as string) === jitSession.userEmail;
  };

  const showTerminateButton = (jitSession: JitSessionDto) => {
    // only admins can terminate sessions
    return isJitSessionActive(jitSession) &&
      can({ user: userConfig }, 'view_admin');
  };
  const loadJitSessions = useCallback(async () => {
    try {
      const result = await getApi(JITApi).jitControllerFilterJitSessions(
        userOnly,
        includeMonthly,
        escalatedOnly,
        numberOfDays,
        currPage,
        pageSize,
        sort,
        sortDirection,
        searchStr,
      );
      setSessions(result.data.sessions);
      setTotal(result.data.total);
    } catch (e: any) {
      alert.error(e);
    }
  }, [
    userOnly,
    includeMonthly,
    escalatedOnly,
    numberOfDays,
    currPage,
    pageSize,
    sort,
    sortDirection,
    searchStr,
    alert,
  ]);

  useEffect(() => {
    loadJitSessions();
  }, [loadJitSessions]);

  useEffect(() => {
    if (props.pageSize) {
      setPageSize(props.pageSize);
    }
    if (props.jitSessions && props.total) {
      setSessions(props.jitSessions);
      setTotal(props.total);
    } else {
      setCurrPage(1);
    }
  }, [props]);

  const SessionDetailsGrid = () => {
    const fieldsToInclude = [
      {
        name: 'startedAt',
        label: tCommon('TIME_START'),
        value: getDateTime(selectedSession.startedAt),
      },
      {
        name: 'endedAt',
        label: tCommon('TIME_END'),
        value: getDateTime(selectedSession.endedAt ?? ''),
      },
      { name: 'env', label: tCommon('ENV') },
      { name: 'companyName', label: tCommon('COMPANY_NAME') },
      { name: 'userEmail', label: tCommon('EMAIL') },
      {
        name: 'accessLevel',
        label: t('ACCESS_LEVEL', { ns: 'jit-audit-log' }),
        value: accessLevels.find((a) => a.id === selectedSession.accessLevel)
          ?.label,
      },
      {
        name: 'impersonateEmail',
        label: t('IMPERSONATE_EMAIL', { ns: 'jit-audit-log' }),
        hidden: !selectedSession.impersonateEmail,
      },
      { name: 'category', label: t('CATEGORY', { ns: 'common' }) },
      { name: 'reason', label: tCommon('REASON') },
      {
        name: 'extensionCount',
        label: t('EXTENSIONS', { ns: 'jit-audit-log' }),
      },
      {
        name: 'salesForceCaseNumber',
        label: t('SALESFORCE_CASE_NUMBER', { ns: 'jit-audit-log' }),
      },
      {
        name: 'redirectUrl',
        label: t('REDIRECT_URL', { ns: 'jit-audit-log' }),
      },
      {
        name: 'usedPin',
        label: `${t('USED_PIN', { ns: 'jit-audit-log' })}?`,
        value: selectedSession.usedPin ? tCommon('YES') : tCommon('NO'),
      },
      {
        name: 'pinGeneratedBy',
        label: t('PIN_GENERATED_BY', { ns: 'jit-audit-log' }),
        hidden: !selectedSession.usedPin,
      },
    ];

    return (
      <React.Fragment>
        {fieldsToInclude.map((field, i) => {
          const { name: fieldName, label: fieldLabel, value, hidden } = field;
          return (
            !hidden && (
              <Grid.Row style={styles.gridRow} key={i}>
                <Grid.Col>
                  <div style={styles.gridLabel}>{fieldLabel}</div>
                </Grid.Col>
                <Grid.Col>
                  <div style={styles.gridValue}>
                    {value
                      ? value
                      : selectedSession[fieldName as keyof JitSessionDto]}
                  </div>
                </Grid.Col>
              </Grid.Row>
            )
          );
        })}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Select
        label={getSelectLabel()}
        data-qa={'date-range-select'}
        onSelect={(v) => {
          changeDateRange(v);
        }}
      >
        {dateRanges.map((item) => (
          <Select.Option key={item.id} value={item}>
            {item.label}
          </Select.Option>
        ))}
      </Select>
      <FlexList>
        <Checkbox
          checked={escalatedOnly}
          data-qa={'escalated-only-checkbox'}
          style={styles.escalatedCheckbox}
          onChange={() => {
            setCurrPage(1);
            setEscalatedOnly(!escalatedOnly);
          }}
        >
          {t('ESCALATED_ONLY', { ns: 'jit-audit-log' })}
        </Checkbox>
        {can({ user: userConfig }, 'view_admin') && (
          <Checkbox
            checked={userOnly}
            data-qa={'user-only-checkbox'}
            style={styles.escalatedCheckbox}
            onChange={() => {
              setCurrPage(1);
              setUserOnly(!userOnly);
            }}
          >
            {t('YOUR_LOGS_ONLY', { ns: 'jit-audit-log' })}
          </Checkbox>
        )}
        <Checkbox
          checked={includeMonthly}
          data-qa={'include-monthly-checkbox'}
          style={styles.escalatedCheckbox}
          onChange={() => {
            setCurrPage(1);
            setIncludeMonthly(!includeMonthly);
          }}
        >
          {t('INCLUDE_PROD_MONTHLY', { ns: 'jit-audit-log' })}
        </Checkbox>
      </FlexList>
      <TableBackendComponent
        headers={tableHeaders}
        data={sessions}
        data_total={total}
        page_size={pageSize}
        default_sort_column="inserted_at"
        default_sort_direction="desc"
        show_no_data_message={true}
        show_page_size_select={true}
        show_search={true}
        paginator={changePage}
        page_size_action={changePageSize}
        search_action={(v: any) => doSearch(v)}
        search_placeholder={t('SEARCH_TEXT', { ns: 'jit-audit-log' })}
        data_qa="jit-audit-log-table"
        current_page={currPage}
        sorter={sortJitSessions}
      />
      {escalateModalOpen && selectedEscalation && (
        <JitEscalateModal
          env={selectedEscalation.env}
          selected_access_level={{
            id: selectedEscalation.accessLevel,
            label: selectedEscalation.accessLevel,
            disabled: true,
          }}
          selected_company={
            {
              companyId: selectedEscalation.companyId,
              companyName: selectedEscalation.companyName,
            } as unknown as JitCompanySettingsDto
          }
          selected_reason={selectedEscalation.category}
          selected_email={selectedEscalation.impersonateEmail}
          isStrategicAccount={isStrategicAccount}
          re_escalate={true}
          isOpen={escalateModalOpen}
          closeModal={closeEscalateModal}
          confirmModal={escalate}
          canSelectEscalationLength={true}
        />
      )}
      {openConfirmModal && (
        <ConfirmModal
          id={'terminate-modal'}
          isOpen={openConfirmModal}
          modalTitle={t('TERMINATE_TITLE', { ns: 'jit-audit-log' })}
          modalCopy={t('TERMINATE_CONFIRM', {
            ns: 'jit-audit-log',
          })}
          closeModal={closeConfirmModal}
          confirmModal={terminateSession}
        />
      )}
      {isDetailsModalOpen && (
        <DataDisplayModal
          show={isDetailsModalOpen}
          id={'details-modal'}
          modalTitle={t('JIT_SESSION_TITLE')}
          modalContent={<SessionDetailsGrid />}
          closeModal={() => setDetailsModalOpen(false)}
        />
      )}
    </React.Fragment>
  );
};

export default JitAuditLog;
