import type { PageConfig } from 'pages/page-config.interfaces';

const CompanyInfoPage: PageConfig = {
  label: 'TAB_INFO',
  route: {
    page: 'info',
    v1_path: '#info',
  },
  i18n: {
    namespace: 'company',
  },
  permissions: {
    edit: ['company_tab_edit'],
  },
  endpoints: {
    view: [
      'companyCompanyInfoControllerGetCompanyGeneralSettings',
      'companyCompanyInfoControllerGetCompanyAccountStatus',
      'toolEntitlementControllerGetEntitlements',
    ],
  },
  cards: {
    AccountStatus: {
      i18n: {
        keyPrefix: 'INFO',
      },
      permissions: {
        edit: ['account_activations'],
      },
      label: 'COMPANY_STATUS_TITLE',
      fields: {},
      endpoints: {
        edit: ['companyCompanyInfoControllerUpdateAccountStatus'],
      },
    },

    OtherSettings: {
      i18n: {
        keyPrefix: 'INFO.SETTINGS',
      },
      label: 'TITLE',
      fields: {
        enable_sandbox: {
          label: 'ENABLE_SANDBOX',
        },
      },
      endpoints: {
        edit: ['companyCompanyInfoControllerUpdateSandboxOptInFlag'],
      },
      permissions: {
        edit: ['company_info_edit'],
      },
    },

    GeneralSettings: {
      i18n: {
        keyPrefix: 'INFO.GENERAL_SETTINGS',
      },
      label: 'TITLE',
      endpoints: {
        edit: ['companyCompanyInfoControllerUpdateCompanyGeneralSettings'],
      },
      permissions: {
        edit: ['company_info_edit'],
      },
      fields: {
        company_name: {
          label: 'COMPANY_NAME',
        },
        // creation_date: {
        //   label: 'CREATION_DATE',
        // },
        time_zone: {
          label: 'TIME_ZONE',
        },
        employer_id_number: {
          label: 'EMPLOYER_ID_NUMBER',
        },
        enable_procore_surveys: {
          label: 'ENABLE_PROCORE_SURVEYS',
        },
        enable_avatars: {
          label: 'ENABLE_AVATARS',
        },
        enable_sd_storage: {
          label: 'ENABLE_SD_STORAGE',
        },
        display_project_number: {
          label: 'DISPLAY_PROJECT_NUMBER',
        },
        locale_data: {
          label: 'LOCALE_DATA',
        },
        storage_profile_key: {
          label: 'STORAGE_PROFILE_KEY',
          tooltip: 'STORAGE_PROFILE_KEY_TOOLTIP',
        },
        percentage_discount: {
          label: 'PERCENTAGE_DISCOUNT',
          tooltip: 'PERCENTAGE_DISCOUNT_TOOLTIP',
        },
      },
    },

    OfficeInfo: {
      i18n: {
        keyPrefix: 'INFO.OFFICE_INFO_CARD',
      },
      label: 'TITLE',
      permissions: {
        edit: ['cs_edit'],
      },
      fields: {},
    },
  },
};

export default CompanyInfoPage;
